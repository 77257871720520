// Core
import { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { compose } from 'recompose';
import { withRoot, withAuth } from 'hocs';

// Styles
import styles from './RepertoireInfoTable.module.css';

function RepertoireInfoTable(props) {
	const { data, contributorRoles } = props;
	const [highlightedAccounts, setHighlightedAccounts] = useState([]);

	const changeBGColor = (selectedAccId, linkedAccounts) => {
		const liTag = document.querySelector(`[account_id-tag="${selectedAccId}"]`);
		if (liTag) {
			linkedAccounts.forEach((acc) => {
				document.querySelector(
					`[account_id-tag="${acc}"]`
				).style.backgroundColor = 'var(--color-light-green)';
			});
		}
	};

	const getSearchDownData = (selectedAccLicensors, result) => {
		let resultedData = [];
		resultedData =
			typeof selectedAccLicensors === 'string'
				? selectedAccLicensors === ''
					? []
					: selectedAccLicensors.split(',').map((str) => Number(str))
				: selectedAccLicensors;
		result = [...result, ...resultedData];
		for (let i = 0; i < resultedData.length; i++) {
			const accWithComparedLicensor = data.find((item) => {
				return (
					item.account_id === +resultedData[i] && item.licensors.length > 0
				);
			});
			if (accWithComparedLicensor) {
				result = getSearchDownData(accWithComparedLicensor.licensors, result);
			}
		}

		return result;
	};

	const getSearchUpData = (account_id, result) => {
		const accWithComparedLicensor = data.filter((item) =>
			item.licensors.includes(+account_id)
		);
		if (accWithComparedLicensor) {
			for (let i = 0; i < accWithComparedLicensor.length; i++) {
				result.push(accWithComparedLicensor[i].account_id);
				result = getSearchUpData(accWithComparedLicensor[i].account_id, result);
			}
		}
		return result;
	};

	const highlightLinkedAccounts = (e) => {
		const account_id = e.currentTarget.getAttribute('account_id-tag');
		const licensors = e.currentTarget.getAttribute('licensors-tag');
		const searchDownData = getSearchDownData(licensors, []);
		const searchUpData = getSearchUpData(account_id, []);
		const resultetOwnershipAccountsList = [...searchDownData, ...searchUpData];
		setHighlightedAccounts(resultetOwnershipAccountsList);
		changeBGColor(account_id, resultetOwnershipAccountsList);
	};

	const resetColor = (e) => {
		const selectedAccId = e.currentTarget.getAttribute('account_id-tag');
		const liTag = document.querySelector(`[account_id-tag="${selectedAccId}"]`);
		if (highlightedAccounts.length) {
			liTag.style.backgroundColor = 'white';
			highlightedAccounts.forEach((acc) => {
				document.querySelector(
					`[account_id-tag="${acc}"]`
				).style.backgroundColor = 'white';
			});
		}
	};

	const showRoles = (rolesArray) => {
		const rolesTitles = rolesArray.map(
			(roleId) => contributorRoles.find((contr) => contr.id === +roleId)?.title
		);

		return rolesTitles.join(', ');
	};

	return (
		<>
			<div className={styles.RepertoireMainInfoTable__body}>
				<ul>
					{data.map((i) => (
						<li
							key={i.account_id}
							account_id-tag={i.account_id}
							licensors-tag={i.licensors}
							onMouseLeave={resetColor}
							onMouseEnter={highlightLinkedAccounts}
						>
							<Row className={styles.row_data}>
								<Row className={styles.row_title}>
									<Col width={'20vw'} className={styles.col_rightHolder}>
										{i.title}
									</Col>
									<Col
										width={
											process.env.REACT_APP_CLIENT !== 'mts' &&
											process.env.REACT_APP_CLIENT !== 'dgtal'
												? '20vw'
												: '25vw'
										}
										className={styles.col_role}
									>
										{i.roles.length > 0 ? showRoles(i.roles) : <></>}
									</Col>
									<Col width={'5vw'} className={styles.col_own}>
										{i.ownership && i.ownership !== '0'
											? +(+i.ownership).toFixed(2) + '%'
											: ''}
									</Col>
									<Col width={'5vw'} className={styles.col_col}>
										{i.collection && i.collection !== '0'
											? +(+i.collection).toFixed(2) + '%'
											: ''}
									</Col>
									{process.env.REACT_APP_CLIENT !== 'mts' &&
										process.env.REACT_APP_CLIENT !== 'dgtal' && (
											<Col width={'5vw'} className={styles.col_rev}>
												{i.revenue && i.revenue !== '0'
													? +(+i.revenue).toFixed(2) + '%'
													: ''}
											</Col>
										)}
								</Row>
								{i.titles && (
									<Row className={styles.row_titles}>
										<Col width={'20vw'} className={styles.col_titlesName}>
											<Row className={styles.row_titles}>
												{i.titles[0].title}
											</Row>
										</Col>
										<Col
											width={
												process.env.REACT_APP_CLIENT !== 'mts' &&
												process.env.REACT_APP_CLIENT !== 'dgtal'
													? '20vw'
													: '25vw'
											}
											className={styles.col_titlesRole}
										>
											<>
												{i.titles[0]?.roles && (
													<Row className={styles.row_titles}>
														{i.titles[0].roles
															.map(
																(item) =>
																	contributorRoles.find(
																		(role) => role.id === item
																	).title
															)
															.join(', ')}
													</Row>
												)}
											</>
										</Col>
										<Col width={'5vw'} className={styles.col_own}></Col>
										<Col width={'5vw'} className={styles.col_col}></Col>
										{process.env.REACT_APP_CLIENT !== 'mts' &&
											process.env.REACT_APP_CLIENT !== 'dgtal' && (
												<Col width={'5vw'} className={styles.col_rev}></Col>
											)}
									</Row>
								)}
							</Row>
						</li>
					))}
				</ul>
			</div>
		</>
	);
}
export default compose(withAuth, withRoot)(RepertoireInfoTable);
