// Core
import { FormattedMessage } from 'react-intl';

// UI
import { Label } from 'components/Labels';

// Styles
import styles from './Dispute.module.css';

const Dispute = ({ unknownError }) => {
	return (
		<div className={styles.page}>
			<div className={styles.form}>
				<div className={styles.title}>
					<Label font="--gilroy-Medium-24" black>
						{unknownError.message}
					</Label>
				</div>
				<div className={styles.detailsWrapper}>
					<Label className={styles.detailsHeader} black>
						<ul>
							{Object.values(unknownError.conditions).map(
								(condition, index) => (
									<li key={index}>
										{condition.title} ({condition.type.title})
									</li>
								)
							)}
						</ul>
					</Label>
				</div>
				<div className={styles.disputeErrorComment}>
					<Label font="--gilroy-Medium-18" black>
						<FormattedMessage id={'rod.composition.dispute.error_body'} />
					</Label>
				</div>
			</div>
		</div>
	);
};

export default Dispute;
