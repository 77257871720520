// Core
import { Popper } from '@mui/material';
import {
	TextFieldInput,
	AutocompleteInput,
} from 'material-design/MuiStyled/MuiStyled';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				background: 'var(--color-light-grey)',
				margin: 0,
				padding: 0,
				height: '192px',
				'& li': {
					backgroundColor: '#FFF',
					font: 'var(--gilroy-Medium-16-150)',
					padding: '12px 8px',
					'&:hover': {
						backgroundColor: '#F0F0F0;',
					},
				},
			},
		},
	})
);

const CustomPopper = function (props) {
	const classes = useStyles();
	return <Popper {...props} className={classes.root} placement="bottom-end" />;
};

const MuiAutocomplete = ({
	onChange,
	label,
	value,
	required,
	index,
	outletsArr,
	errors,
	...props
}) => {
	if (value) {
		for (let j = 0; j < outletsArr.length; j++) {
			if (value === outletsArr[j].id) {
				value = {
					id: outletsArr[j].id,
					outlet: outletsArr[j].title,
				};
			}
		}
	}

	const filterOptions = (_, state) => {
		let newOptions = [];
		if (state.inputValue) {
			outletsArr.forEach((item) => {
				if (
					item.title.toLowerCase().includes(state.inputValue.toLowerCase()) &&
					item.title.toLowerCase().indexOf(state.inputValue.toLowerCase()) === 0
				)
					newOptions.push(item);
			});

			return newOptions;
		} else {
			return outletsArr;
		}
	};

	return (
		<>
			<AutocompleteInput
				id="disable-clearable"
				disableClearable
				onChange={(_, obj) => onChange(obj)}
				options={outletsArr}
				value={value}
				getOptionLabel={(option) => {
					return option[index]?.outlet || option?.title || '';
				}}
				isOptionEqualToValue={(option, value) => {
					return option.title === value.outlet;
				}}
				renderInput={(params) => (
					<TextFieldInput
						{...params}
						label={label}
						variant="standard"
						required={required}
						error={errors}
					/>
				)}
				renderOption={(option, item) => {
					option = { ...option, key: item.id };
					return (
						<li key={item.id} {...option}>
							{item.title}
						</li>
					);
				}}
				PopperComponent={CustomPopper}
				filterOptions={filterOptions}
			/>
		</>
	);
};

export default MuiAutocomplete;
