// Core
import { format } from 'date-fns';

export const toGeneralFormat = (data) => {
	return data.map((track) => {
		if (track.created_date.length) {
			const correctedTime = new Date(
				new Date(track.created_date).getTime() -
					new Date().getTimezoneOffset() * 60000
			).toISOString();
			track.created_date = correctedTime.slice(0, correctedTime.indexOf('T'));
		} else {
			const correctedDate = format(new Date(track.created_date), 'yyyy-MM-dd');
			track.created_date = correctedDate;
		}

		return {
			...track,
			featured_artist:
				data.featured_artist === '' ? null : data.featured_artist,
			producer: data.producer === '' ? null : data.producer,
			generate_isrc: data.generate_isrc === 1 ? true : false,
			generate_catalog_number:
				data.generate_catalog_number === 1 ? true : false,
		};
	});
};
