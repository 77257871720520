// Core
import React, { useEffect, useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// Services
import { withAuth, withUI, withRoot } from 'hocs';
import { compose } from 'recompose';

// UI
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {
	RepertoireRecordingInfo,
	RepertoireRecordingReleases,
	RepertoireRecordingComposition,
	RepertoireRecordingNotices,
} from './RepertoireRecordingTab';
import Explicit from 'components/Explicit/Explicit';

// Icons
import dynamic from 'images/dynamic.svg';

// Styles
import { HeaderButton } from './HeaderButton.styled';
import styles from './RepertoireRecording.module.css';

function RepertoireRecording(props) {
	const [recordingMetadata, setRecordingMetadata] = useState(false);
	const [isNotices, setisNotices] = useState(false);
	const match = useRouteMatch();

	const {
		UIContext: {
			showBackNavi,
			hideBackNavi,
			changeBackTargetUrl,
			setPrevRepertoireParams,
			isAccountFeatureCompositionView,
		},
		rootContext: {
			getRecordingMetadata,
			getRecordingsCompositions,
			getRecordingIssues,
		},
		authContext: { accountId },
	} = props;
	const recordingId = match.params.id;
	const { lang } = useContext(LangContext);

	useEffect(() => {
		getRecordingMetadata(accountId, recordingId).then((res) => {
			setRecordingMetadata(res.data);
		});

		getRecordingIssues(accountId, recordingId, lang)
			.then((res) => {
				setisNotices(
					res && Array.isArray(res) && res.length > 0 ? true : false
				);
			})
			.catch((error) => {
				console.error('Error', error);
			});

		changeBackTargetUrl('/repertoire/recordings');
		showBackNavi('/repertoire/recordings', null, 'rod.action.back_to_list');

		return function cleanup() {
			hideBackNavi();
			setPrevRepertoireParams({ page: 1 });
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<TabsUnstyled defaultValue={0} className={styles.repertoireSong}>
			<div className={styles.repertoireSong__titleWrapper}>
				<img
					className={styles.repertoireSong__logo}
					src={dynamic}
					width="32px"
					height="32px"
					alt=""
				/>
				<span className={styles.repertoireSong__title}>
					{recordingMetadata && recordingMetadata.title
						? recordingMetadata.title
						: '-'}
				</span>
				{recordingMetadata &&
					recordingMetadata.parental_warning_type &&
					recordingMetadata.parental_warning_type === 'explicit' && (
						<Explicit />
					)}
				<div className={styles.repertoireSong__isrc2}>
					<span>ISRC:</span>
					<span>
						&nbsp;
						{recordingMetadata && recordingMetadata.isrc
							? recordingMetadata.isrc
							: '-'}
					</span>
				</div>
			</div>
			<TabsListUnstyled className={styles.repertoireSong__header}>
				<HeaderButton className={styles.headerButton}>
					<FormattedMessage id={'rod.recordery.tab.main'} />
				</HeaderButton>
				{isAccountFeatureCompositionView && (
					<HeaderButton className={styles.headerButton}>
						<FormattedMessage id={'rod.recordery.tab.composition'} />
					</HeaderButton>
				)}
				<HeaderButton className={styles.headerButton}>
					<FormattedMessage id={'rod.recordery.tab.releases'} />
				</HeaderButton>
				{isNotices && (
					<HeaderButton className={styles.headerButton}>
						<FormattedMessage id={'rod.release.tab.not_ready'} />
					</HeaderButton>
				)}
			</TabsListUnstyled>
			<div className={styles.repertoireSong__main}>
				<TabPanelUnstyled value={0}>
					<RepertoireRecordingInfo />
				</TabPanelUnstyled>
				{isAccountFeatureCompositionView && (
					<TabPanelUnstyled value={1}>
						<RepertoireRecordingComposition
							accountId={accountId}
							recordingId={recordingId}
							getRecordingsCompositions={getRecordingsCompositions}
						/>
					</TabPanelUnstyled>
				)}
				<TabPanelUnstyled value={isAccountFeatureCompositionView ? 2 : 1}>
					<RepertoireRecordingReleases />
				</TabPanelUnstyled>
				{isNotices && (
					<TabPanelUnstyled value={isAccountFeatureCompositionView ? 3 : 2}>
						<RepertoireRecordingNotices />
					</TabPanelUnstyled>
				)}
			</div>
		</TabsUnstyled>
	);
}
export default compose(withAuth, withUI, withRoot)(RepertoireRecording);
