export const getMarginLeftForInfoNotActive = (field, lang) => {
	if (lang === 'ru') {
		switch (field) {
			case 'ipi_name_number':
				return '-8px';
			case 'ipn':
				return '0px';
			case 'isni':
				return '4px';
			default:
				return '8px';
		}
	} else {
		switch (field) {
			case 'ipi_name_number':
				return '-1px';
			case 'ipn':
				return '8px';
			case 'isni':
				return '12px';
			default:
				return '8px';
		}
	}
};

export const getMarginLeftForInfoActive = (field, lang) => {
	if (lang === 'ru') {
		switch (field) {
			case 'ipi_name_number':
				return '0px';
			case 'ipn':
				return '4px';
			case 'isni':
				return '8px';
			default:
				return '8px';
		}
	} else {
		switch (field) {
			case 'ipi_name_number':
				return '4px';
			case 'ipn':
				return '10px';
			case 'isni':
				return '12px';
			default:
				return '8px';
		}
	}
};
