// Core
import React, { PureComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';

// Utils
import update from 'immutability-helper';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getHelpMessage } from '../../../../../messages/helper';
import { compose } from 'recompose';
import { generatePaymentsSteps } from '../utils/generatePaymentsSteps';
import { replaceSpaces } from 'utils';

// UI
import { Loading } from 'components';
import { Button } from 'components/Buttons';
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { Wizard } from 'components/Wizard';
import { InfoButton } from 'components/Buttons';
import paymentTypes from 'constants/paymentTypes';
import { withAuth, withLang, withRoot, withUI } from 'hocs';

// Styles
import styles from './Payments.module.css';

class Payments extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.state = {
			isShow: true,
			loading: true,
			payments: {
				payment_channel: paymentTypes.BANK,
			},
		};
		this.form = React.createRef();
		this.steps = generatePaymentsSteps(props.personalData.business_type);
	}

	nextHandler = (e) => {
		const { isShow } = this.state;
		e.preventDefault();

		if (typeof this.props.onChange === 'function') {
			if (isShow) {
				let correctedState = { ...this.state.payments };
				if (
					Object.keys(correctedState).length <= 1 &&
					correctedState.payment_channel &&
					correctedState.payment_channel === paymentTypes.BANK
				) {
					correctedState = {
						...correctedState,
						bank_account_number: '',
						bank_account_swift: '',
						bank_account_title: '',
						bank_address: '',
						bank_correspondent_account_number: '',
						bank_correspondent_account_swift: '',
						bank_title: '',
						bank_country_id: null,
					};
				}
				if (this.state.payments.payment_channel === paymentTypes.BANK) {
					delete correctedState.paypal;
				}
				if (this.state.payments.payment_channel === paymentTypes.PAYPAL) {
					delete correctedState.bank_account_number;
					delete correctedState.bank_account_swift;
					delete correctedState.bank_account_title;
					delete correctedState.bank_address;
					delete correctedState.bank_correspondent_account_number;
					delete correctedState.bank_correspondent_account_swift;
					delete correctedState.bank_title;
				}
				this.props.onChange(correctedState);
			} else {
				this.props.onChange({});
			}
		}
	};

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}

		if (
			prevProps.personalData.business_type !==
			this.props.personalData.business_type
		) {
			this.steps = generatePaymentsSteps(this.props.personalData.business_type);
		}
	}

	update() {
		const {
			authContext: { changeWidget, setIsAccountLoading },
			rootContext: { getAccountPaymentData },
		} = this.props;

		setIsAccountLoading(true);
		changeWidget();
		getAccountPaymentData(this.props.id)
			.then((res) => {
				res = res.data.data;
				const { ...rest } = res;
				this.setState(
					update(this.state, {
						payments:
							Array.isArray(res) && !res.length
								? { $set: { payment_channel: paymentTypes.BANK } }
								: { $set: { ...rest } },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			})
			.catch((error) => {
				console.error('Payments - getAccountPaymentData error: ', error);
				console.error(
					'Payments - getAccountPaymentData error.response: ',
					error.response
				);
				setIsAccountLoading(false);
			});
	}

	isNeedSpacesReplace = (field) => {
		return (
			field === 'bank_account_swift' ||
			field === 'bank_correspondent_account_number' ||
			field === 'bank_correspondent_account_swift' ||
			field === 'bank_account_number'
		);
	};

	changeField = (field) => (value) => {
		if (
			field === 'bank_correspondent_account_number' &&
			!Number.isInteger(+value)
		) {
			return;
		} else {
			this.setState(
				update(this.state, {
					payments: {
						[field]: {
							$set: this.isNeedSpacesReplace(field)
								? replaceSpaces(value)
								: value,
						},
					},
				})
			);
		}
	};

	renderPaymentFields() {
		const {
			UIContext: { showModal },
			rootContext: { countries },
			errors,
			businessType,
		} = this.props;

		const lang = localStorage.getItem('lang');
		const message = DOMPurify.sanitize(...getHelpMessage(lang, 'payments'));
		const { isShow, payments } = this.state;

		return (
			<div>
				<Row>
					<Col sm={11} offset={{ sm: 1 }}>
						<div className={styles.HeaderLine}>
							<div
								className={styles.labelWrapper}
								style={{ 'margin-bottom': '34px' }}
							>
								<InfoButton
									className={styles.infoButton}
									onClick={showModal({
										text: parse(message),
									})}
								>
									<Label
										text={
											<FormattedMessage
												id={'rod.account.create.payments.header'}
											/>
										}
										className={styles.Header}
										font="--gilroy-Medium-40"
										display="inline"
										black
									/>
								</InfoButton>
							</div>
						</div>
					</Col>
				</Row>
				{isShow && (
					<form onSubmit={this.nextHandler} ref={this.form}>
						<Row>
							{payments.type && (
								<Col sm={5} offset={{ sm: 0 }} className={styles.Header}>
									<Label
										className="subheader"
										text={<FormattedMessage id={'rod.form.required_title'} />}
										font="--gilroy-Medium-18"
										black
									/>
								</Col>
							)}
						</Row>
						{payments.payment_channel === paymentTypes.BANK && (
							<Row>
								<Col sm={5} offset={{ sm: 1 }}>
									<FormattedMessage
										id={
											businessType === 'individual'
												? 'rod.field.beneficiary_name.individual'
												: 'rod.field.beneficiary_name'
										}
									>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_title'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_account_name'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_number'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_name'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_title'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.swift_code'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_account_swift'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
								<Col sm={5}>
									<FormattedMessage id={'rod.field.bank_country'}>
										{(placeholder) => (
											<FormInput
												type="country"
												countries={countries}
												placeholder={placeholder}
												name={'bank_country_id'}
												onChange={this.changeField}
												errors={errors}
												required
												data={payments}
												className={styles.CountryInput}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.bank_address'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_address'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
												required
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.cor_account'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_number'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
											/>
										)}
									</FormattedMessage>
									<FormattedMessage id={'rod.field.cor_swift'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'bank_correspondent_account_swift'}
												onChange={this.changeField}
												errors={errors}
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
							</Row>
						)}

						{payments.payment_channel === paymentTypes.PAYPAL && (
							<Row>
								<Col sm={5} offset={{ sm: 1 }}>
									<FormattedMessage id={'rod.field.paypal_account'}>
										{(placeholder) => (
											<FormInput
												placeholder={placeholder}
												name={'paypal'}
												onChange={this.changeField}
												helper={
													<FormattedMessage
														id={'rod.field.paypal_account.helper'}
													/>
												}
												errors={errors}
												required
												data={payments}
											/>
										)}
									</FormattedMessage>
								</Col>
							</Row>
						)}
					</form>
				)}
			</div>
		);
	}

	render() {
		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}

		return (
			<div className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={this.props.handleOpenPrevTabs}
				/>
				<div className={styles.Container}>
					{this.renderPaymentFields()}
					{this.renderControls()}
				</div>
			</div>
		);
	}

	renderControls() {
		return (
			<Row>
				<Col md={5} offset={{ sm: 1 }}>
					<div className={styles.Controls}>
						<Button
							text={<FormattedMessage id={'rod.action.complete'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
				</Col>
			</Row>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Payments);
