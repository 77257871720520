// Core
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import cloneDeep from 'lodash/cloneDeep';
import { withUI } from 'hocs';
import { accounts } from 'services';
import { v4 as uuidv4 } from 'uuid';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import AddRecordingBtn from './AddRecordingBtn/AddRecordingBtn';
import { BottomNavi, InfoHelper } from 'components';
import RecordingsList from './RecordingsList/RecordingsList';
import RadioContainer from './RadioContainer/RadioContainer';

// Styles
import s from './PerformanceWork.module.css';

const PerformanceWork = (props) => {
	const {
		showBackNavi,
		hideBackNavi,
		disableRecordingBtns,
		setDisableRecordingBtns,
		setIsBottomNaviShow,
	} = useContext(UIContext);

	const [selectedValue, setSelectedValue] = useState('');
	const [isNewRecording, setIsNewRecording] = useState(false);
	const [newRecordings, setNewRecordings] = useState([]);
	const [performanceWork, setPerformanceWork] = useState({});
	const [isEdit, setIsEdit] = useState(true);
	const [errors, setErrors] = useState({});
	const [disabledSaveBtn, setDisabledSaveBtn] = useState(false);
	const [disableBtn, setDisableBtn] = useState(false);

	const compositionId = localStorage.getItem('compositionId');

	const handleRadioGroup = (e) => {
		setSelectedValue(e.currentTarget.value);
		setDisableRecordingBtns(false);
	};

	const backHandler = () => {
		if (typeof props.handlePrevStep === 'function') {
			accounts.getCompositionData(compositionId).then((res) => {});
			props.handlePrevStep();
		}
	};

	const nextHandler = () => {
		setDisableBtn(true);
		if (typeof props.onChange === 'function') {
			if (selectedValue === 'no') {
				setIsNewRecording(false);
				setNewRecordings([]);
				performanceWork.recordings = [];
				setPerformanceWork({ ...performanceWork });
			}

			accounts
				.uploadCompositionPerformed(compositionId, {
					performed: selectedValue === 'yes' ? true : false,
				})
				.then(() =>
					props
						.onChange()
						.then()
						.catch()
						.finally(() => setDisableBtn(false))
				)
				.catch((error) => {
					console.error('Error', error);
					setDisableBtn(false);
				});
		}
	};

	const showCreatingRecordsForm = () => {
		if (performanceWork.recordings && performanceWork.recordings.length === 0) {
			setNewRecordings([{ id: uuidv4() + 'local' }]);
		}
		setIsNewRecording(true);
	};

	const onAddRecording = () => {
		setIsEdit(false);
		setDisableRecordingBtns(true);
		setNewRecordings((newRecordings) => [
			...newRecordings,
			{ id: uuidv4() + 'local' },
		]);
	};

	const updateRecording = async (data) => {
		setErrors({});
		const recordingId = data['id'];

		if (typeof props.onChange === 'function') {
			try {
				await accounts.updateCompositionRecording(
					compositionId,
					recordingId,
					data
				);
				return true;
			} catch (error) {
				console.error('Error', error);
				setErrors({
					id: recordingId,
					status: error.response.status,
					errors: error.response.data.errors,
				});
				throw error;
			}
		}
	};

	const uploadRecording = async (data) => {
		setErrors({});

		if (typeof props.onChange === 'function') {
			try {
				const res = await accounts.uploadCompositionRecording(
					compositionId,
					data
				);
				const responseData = res.data.data;

				if (props.lastStep === 'authors_shares') {
					await accounts.updateCompositionStep(compositionId, {
						step: 'composition_recordings',
					});
					props.setStep('composition_recordings');
				}

				if (
					typeof newRecordings[0]['id'] === 'string' &&
					newRecordings.length === 1
				) {
					setNewRecordings([responseData]);
				} else {
					const lessRecordings = newRecordings.filter(
						(record) => record['id'] !== data['id']
					);
					setNewRecordings([...lessRecordings, responseData]);
				}
				return true;
			} catch (error) {
				console.error('Error', error);
				setErrors({
					id: data.id,
					status: error.response.status,
					errors: error.response.data.errors,
				});
				throw error;
			}
		}
	};

	const updateRecordingsFromBack = () => {
		accounts
			.getCompositionData(compositionId)
			.then((res) => {
				res.data.data['created_date'] = res.data.data['created_date']?.match(
					/(\d{4})-(\d{2})-(\d{2})/gm
				)[0];
				const data = res.data.data;
				setNewRecordings(data.recordings);
			})
			.catch((error) => {
				console.error('Error', error);
				setErrors(error.response.data.errors);
			});
	};

	const recordingSave = async (data) => {
		setDisableRecordingBtns(false);
		setDisabledSaveBtn(true);

		const clone = cloneDeep(data);

		if (clone.created_date) {
			const correctedTime = new Date(
				new Date(clone.created_date).getTime() -
					new Date().getTimezoneOffset() * 60000
			).toISOString();

			clone.created_date = correctedTime.slice(0, correctedTime.indexOf('T'));
		}

		if (clone.genres) {
			const genres = clone.genres.map((item) => (item.code ? item.code : item));
			clone.genres = genres;
		}

		if (clone.main_performer) {
			const performersArr = [...clone.main_performer];
			const formattedArr = performersArr.map((item) =>
				item.artist_id ? item.artist_id : item.name ? item.name : item
			);
			clone.main_performer = formattedArr.filter(
				(item) => item !== '' && !(typeof item === 'object' && item.name === '')
			);
		}

		if (clone.featured_artist) {
			const performersArr = [...clone.featured_artist];
			const formattedArr = performersArr.map((item) =>
				item.artist_id ? item.artist_id : item.name ? item.name : item
			);
			clone.featured_artist = formattedArr.filter(
				(item) => item !== '' && !(typeof item === 'object' && item.name === '')
			);
		}

		if (newRecordings && typeof clone.id === 'string') {
			try {
				await uploadRecording(clone);
				setIsEdit(true);
				setDisabledSaveBtn(false);
				return true;
			} catch (error) {
				console.error('Error', error);
				setErrors({
					id: clone.id,
					status: error.response.status,
					errors: error.response.data.errors,
				});
				setDisabledSaveBtn(false);
				return false;
			}
		}

		if (newRecordings && typeof clone.id !== 'string') {
			try {
				await updateRecording(clone);
				setDisabledSaveBtn(false);
				return true;
			} catch (error) {
				console.error('Error', error);
				setErrors({
					id: clone.id,
					status: error.response.status,
					errors: error.response.data.errors,
				});
				setDisabledSaveBtn(false);
				return false;
			}
		}

		if (newRecordings.find((record) => record['id'] === clone['id'])) {
			const lessRecordings = newRecordings.filter(
				(record) => record['id'] !== clone['id']
			);
			setNewRecordings([...lessRecordings, clone]);
			updateRecording(clone);
			updateRecordingsFromBack();
			accounts
				.updateCompositionStep(compositionId, {
					step: 'composition_recordings',
				})
				.then((res) => {})
				.catch((error) => {
					console.error('Error', error);
					setErrors(error.response.data.errors);
					setDisabledSaveBtn(false);
				});
		}
	};

	const handleDeleteRecording = (id) => {
		let remainRecordings = newRecordings.filter((item) => item.id !== id);

		if (remainRecordings.length === 0) {
			setDisableRecordingBtns(true);
		} else {
			setDisableRecordingBtns(false);
		}

		remainRecordings =
			remainRecordings.length === 0
				? [{ id: uuidv4() + 'local' }]
				: remainRecordings;

		if (typeof id !== 'string') {
			accounts
				.deleteCompositionRecording(compositionId, id)
				.then((res) => {
					setNewRecordings(remainRecordings);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		} else setNewRecordings(remainRecordings);

		if (
			remainRecordings.length === 1 &&
			typeof remainRecordings[0].id === 'string'
		) {
			setIsEdit(false);
		}
	};

	const changeField = (field) => (e) => {
		if (field === 'create_new_recordings') {
			performanceWork[field] = e.target.checked;
			setPerformanceWork(performanceWork);
			setIsNewRecording(e.target.checked);
			return;
		}

		performanceWork[field] = e?.target?.value ? e.target.value : e;
		setPerformanceWork({ ...performanceWork });
	};

	useEffect(() => {
		setIsBottomNaviShow(true);

		return () => {
			setIsBottomNaviShow(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (compositionId) {
			accounts
				.getCompositionData(compositionId)
				.then((res) => {
					res.data.data['created_date'] = res.data.data['created_date']?.match(
						/(\d{4})-(\d{2})-(\d{2})/gm
					)[0];

					if (res.data.data.recordings && res.data.data.recordings.length) {
						res.data.data.recordings.forEach((item) => {
							if (!item.main_performer) {
								item.main_performer = [''];
							} else {
								if (
									typeof item.main_performer === 'string' ||
									typeof item.main_performer === 'number'
								) {
									item.main_performer = [
										{ name: item.main_performer.toString() },
										'',
									];
								} else {
									const formattedPerformers = item.main_performer?.map(
										(item) => {
											if (
												typeof item === 'string' ||
												typeof item === 'number'
											) {
												return { name: item.toString() };
											}
											return item;
										}
									);
									item.main_performer = [...formattedPerformers, ''];
								}
							}
						});
					}

					if (res.data.data.recordings && res.data.data.recordings.length) {
						res.data.data.recordings.forEach((item) => {
							if (!item.featured_artist) {
								item.featured_artist = [''];
							} else {
								if (typeof item.featured_artist === 'string') {
									item.featured_artist = [{ name: item.featured_artist }, ''];
								} else {
									const formattedPerformers = item.featured_artist?.map(
										(item) => {
											if (typeof item === 'string') {
												return { name: item };
											}
											return item;
										}
									);
									item.featured_artist = [...formattedPerformers, ''];
								}
							}
						});
					}

					const data = res.data.data;
					setPerformanceWork(data);
					if (data.recordings.length > 0) {
						setNewRecordings(data.recordings);
						setIsEdit(true);
					}
					setSelectedValue('yes');
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}

		showBackNavi();

		return function cleanup() {
			hideBackNavi();
			setDisableRecordingBtns(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNewRecording === false) {
			setErrors({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNewRecording]);

	useEffect(() => {
		setErrors({});

		if (selectedValue === 'yes') {
			showCreatingRecordsForm();
			setIsNewRecording(true);
			setIsEdit(true);

			if (newRecordings.length === 0) {
				setIsEdit(false);
				setDisableRecordingBtns(true);
			}

			if (
				newRecordings.length === 1 &&
				typeof newRecordings[0].id === 'string'
			) {
				setIsEdit(false);
			}

			if (
				newRecordings.length >= 1 &&
				typeof newRecordings[newRecordings.length - 1].id === 'string'
			) {
				const savedRecordings = newRecordings.slice(0, -1);
				setNewRecordings(savedRecordings);
			}
		} else if (selectedValue === 'no') {
			setIsNewRecording(false);
		}
		// eslint-disable-next-line
	}, [selectedValue]);

	return (
		<>
			<div className={s.page}>
				<div className={s.main}>
					<h2 className={s.title}>
						<FormattedMessage id={'rod.add_composition_page.step4'} />
					</h2>
					<p className={s.required_text}>
						<FormattedMessage id={'rod.label.required_fields'} />
					</p>

					<RadioContainer
						selectedValue={selectedValue}
						handleRadioGroup={handleRadioGroup}
					/>

					{isNewRecording ? (
						<div>
							<RecordingsList
								recordings={newRecordings}
								onRemoveClick={handleDeleteRecording}
								newRecordings={onAddRecording}
								recordingSave={recordingSave}
								data={performanceWork}
								isEdit={isEdit}
								onChange={changeField}
								errors={errors}
								setErrors={setErrors}
								disabledSaveBtn={disabledSaveBtn}
							/>
							<AddRecordingBtn
								onAddRecording={onAddRecording}
								disabled={disableRecordingBtns || disabledSaveBtn}
							/>
						</div>
					) : (
						''
					)}
				</div>
				<InfoHelper text="rod.composition.create.step.performance.helper_body" />
			</div>
			<BottomNavi
				showPrevBtn
				disabled={disabledSaveBtn || disableBtn}
				back={backHandler}
				next={nextHandler}
			/>
		</>
	);
};

export default withUI(PerformanceWork);
