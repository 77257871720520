// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';
import MultipleSelectPerformers from 'material-design/components/MuiMultipleCheckedPerformers/MuiMultipleCheckedPerformers';
import MultipleSelectRecordings from 'material-design/components/MuiMultipleCheckedRecordings/MuiMultipleCheckedRecordings';
import MultipleSelectReleases from 'material-design/components/MuiMultipleCheckedReleases/MuiMultipleCheckedReleases';

import { Button } from 'components/Buttons';

// Styles
import styles from './StatisticFilterModal.module.css';

const StatisticFilterModalDate = ({
	active,
	setActive,
	isAdmin,
	accountId,
	errors,
	lang,
	setLoading,
	startEndDates,
	handleClearAllFilters,
	chartMode,
	recordingsForRender,
	releasesForRender,
	handleResetAllFilters,
	setActiveFilters,
	checkedTopTrack,
	handleResetTopTrack,
	setHasRecordingsForRender,
	setPage,
}) => {
	const [filtersData, setFiltersData] = useState({
		tracksIds: [],
		releases: [],
		performers: [],
	});

	const [isInitial, setIsInitial] = useState(false);
	const [performers, setPerformers] = useState([]);
	const [releases, setReleases] = useState([]);
	const [recordings, setRecordings] = useState([]);
	const [updatekey, setUpdateKey] = useState(Math.random() * 100);
	const [disabled, setDisabled] = useState(false);
	const [resetRecordings, setResetRecordings] = useState(false);

	const [isGetReleases, setIsGetReleases] = useState(true);
	const [isGetRecordings, setIsGetRecordings] = useState(true);

	const [isResetPerformers, setIsResetPerformers] = useState(false);
	const [isResetReleases, setIsResetReleases] = useState(false);

	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);

	useEffect(() => {
		if (
			isInitial &&
			recordings.length &&
			performers.length &&
			releases.length
		) {
			setIsInitial(false);
		}
	}, [isInitial, recordings, performers, releases]);

	useEffect(() => {
		if (!recordings.length || !isGetReleases || !isGetRecordings) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [performers, releases, recordings, isGetReleases, isGetRecordings]);

	const handleApplyAllFilters = () => {
		recordingsForRender(resetRecordings ? [] : recordings);
		releasesForRender(releases.length ? releases : []);
		setActive(false);
		setActiveFilters(resetRecordings ? false : true);
		setPage(1);
	};

	const handleClearAll = () => {
		handleResetAllFilters();
		setFiltersData({
			tracksIds: [],
			releases: [],
			performers: [],
		});
		setPerformers([]);
		setReleases([]);
		setRecordings([]);
		setUpdateKey(Math.random() * 100);
		handleClearAllFilters();
		setActive(false);
		setActiveFilters(false);
		handleResetTopTrack();
	};

	useEffect(() => {
		setLoading(true);
	}, []);

	return (
		<>
			<div
				className={
					active ? `${styles.Overlay} ${styles.OverlayActive}` : styles.Overlay
				}
			/>
			<div
				id="wrapper-overlay"
				className={active ? `${styles.modal} ${styles.active}` : styles.modal}
				onClick={() => setActive(false)}
			>
				<div
					id="wrapper-drawer"
					key={updatekey}
					className={styles.container}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={styles.modalHeader}>
						<div className={styles.modalHeader__titleWrapper}>
							<span className={styles.modalHeader__title}>
								<FormattedMessage id={'rod.release.info.filter'} />
							</span>
							<span
								onClick={handleClearAll}
								className={styles.modalHeader__clear}
							>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</span>
							<div className={styles.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.modalBody}>
						<div className={styles.setting}>
							<div className={styles.inputTrack}>
								<MultipleSelectPerformers
									isAdmin={isAdmin}
									accountId={accountId}
									className={styles.tracksCheckmarks}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									setLoading={setLoading}
									setPerformersState={setPerformers}
									setIsResetPerformers={setIsResetPerformers}
									lang={lang}
									chartMode={chartMode}
								/>
							</div>
							{chartMode === 'phono' && (
								<div className={styles.inputTrack}>
									<MultipleSelectReleases
										isAdmin={isAdmin}
										accountId={accountId}
										dateFrom={startEndDates[0]}
										dateTo={startEndDates[1]}
										setLoading={setLoading}
										artists={performers}
										releasesState={setReleases}
										lang={lang}
										chartMode={chartMode}
										setResetRecordings={setResetRecordings}
										setIsResetPerformers={setIsResetPerformers}
										isResetPerformers={isResetPerformers}
										setIsResetReleases={setIsResetReleases}
										setIsGetReleases={setIsGetReleases}
									/>
								</div>
							)}
							{chartMode === 'phono' ? (
								<div className={styles.inputTrack}>
									<MultipleSelectRecordings
										isAdmin={isAdmin}
										accountId={accountId}
										className={styles.tracksCheckmarks}
										dateFrom={startEndDates[0]}
										dateTo={startEndDates[1]}
										setLoading={setLoading}
										releaseIds={releases}
										recordingsState={setRecordings}
										lang={lang}
										chartMode={chartMode}
										topTrack={checkedTopTrack}
										setHasRecordingsForRender={setHasRecordingsForRender}
										setResetRecordings={setResetRecordings}
										setIsGetRecordings={setIsGetRecordings}
										setIsResetPerformers={setIsResetPerformers}
										isResetPerformers={isResetPerformers}
										setIsResetReleases={setIsResetReleases}
										isResetReleases={isResetReleases}
										handleResetTopTrack={handleResetTopTrack}
									/>
								</div>
							) : (
								<div className={styles.inputTrack}>
									<MultipleSelectRecordings
										isAdmin={isAdmin}
										accountId={accountId}
										className={styles.tracksCheckmarks}
										dateFrom={startEndDates[0]}
										dateTo={startEndDates[1]}
										setLoading={setLoading}
										artists={performers}
										recordingsState={setRecordings}
										lang={lang}
										chartMode={chartMode}
										topTrack={checkedTopTrack}
										setHasRecordingsForRender={setHasRecordingsForRender}
										setResetRecordings={setResetRecordings}
										setIsGetRecordings={setIsGetRecordings}
										setIsResetPerformers={setIsResetPerformers}
										isResetPerformers={isResetPerformers}
										setIsResetReleases={setIsResetReleases}
										isResetReleases={isResetReleases}
										handleResetTopTrack={handleResetTopTrack}
									/>
								</div>
							)}
						</div>
						<Button
							className={styles.submit_button}
							variant="primary"
							onClick={() => handleApplyAllFilters()}
							disabled={disabled}
						>
							<FormattedMessage id={'rod.release.info.apply'} />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default StatisticFilterModalDate;
