// Core
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { showError } from 'validators/showError';
import debounce from 'lodash.debounce';

// Context
import { RootContext } from 'contexts/RootContext';
import { LangContext } from 'contexts/LangContext';

// UI
import { InputWithFloatingLabel } from '../InputWithFloatingLabel';
import clearImg from 'images/close_button.svg';
import Skeleton from 'material-design/components/MuiMultipleCheckedTracks/MuiMultipleCheckedAnimation';

// Styles
import styles from './SocietiesAutosuggestInput.module.css';

const SocietiesAutosuggestInput = ({
	value: initialValue,
	onChange,
	placeholder,
}) => {
	const [value, setValue] = useState(initialValue);
	const [items, setItems] = useState([]);
	const [text, setText] = useState(initialValue?.title);
	const [isLoading, setLoading] = useState(false);

	const { searchAuthorSocieties } = useContext(RootContext);
	const { lang } = useContext(LangContext);

	useEffect(() => {
		if (initialValue !== value) {
			setValue(initialValue);
			setText(initialValue?.title);
		}
	}, [initialValue, value]);

	const searchAuthorSocietiesData = useCallback(
		async (searchValue) => {
			try {
				if (searchValue.length < 2) {
					setItems([]);
					return;
				}
				setLoading(true);
				const res = await searchAuthorSocieties(searchValue);
				setItems(res.data.data);
			} catch (err) {
				console.error('Error:', err);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[setItems]
	);

	const clear = () => {
		setValue(null);
		setText('');
		onChange({});
	};

	const change = (item) => () => {
		setItems([]);
		setValue(item);
		setText(item.title);
		onChange(item);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedChangeHandler = useCallback(
		debounce((searchValue) => searchAuthorSocietiesData(searchValue), 500),
		[searchAuthorSocietiesData]
	);

	const changeValue = (searchValue) => {
		if (searchValue.length > 2) {
			setLoading(true);
		} else if (searchValue.length < 2 && isLoading) {
			setLoading(false);
		}
		setText(searchValue);
		debouncedChangeHandler(searchValue);
	};

	return (
		<div className={styles.component}>
			<InputWithFloatingLabel
				placeholder={placeholder}
				showError={showError('society')}
				required
				value={text}
				onChange={changeValue}
			/>
			{text && (
				<button className={styles.icon} onClick={clear}>
					<img src={clearImg} alt="clear" />
				</button>
			)}
			{isLoading && (
				<div className={styles.skeleton}>
					<Skeleton />
				</div>
			)}
			{!isLoading && text && !value && text.length > 2 && items.length === 0 && (
				<div className={styles.dropdown}>
					<ul className={styles.list}>
						<li>
							{lang === 'ru' ? 'Ничего не найдено' : 'No suggestions found'}
						</li>
					</ul>
				</div>
			)}
			{items.length > 0 && (
				<div className={styles.dropdown}>
					<ul className={styles.list}>
						{items.map((item) => (
							<li key={item.id} onClick={change(item)}>
								{item.title}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default SocietiesAutosuggestInput;
