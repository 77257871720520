/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 10/03/2021, 19:16
 */
// eslint-disable-next-line
export default {
	RETRY: 'retry',
	INVALID: 'invalid',
	VALIDATION_CYRILLIC: 'validation.cyrillic',
	CYRILLIC_INVALID: 'cyrillic_invalid',
	FORBIDDEN: 'forbidden',
	USER_EXIST: 'user_exist',
	MAX_LENGTH: 'max_length',
	MIN_LENGTH: 'min_length',
	MIN_AGE: 'min_age',
	REQUIRED: 'required',
	REQUIRED_UNLESS: 'required_unless',
	EXISTS_IN_SIGNUP: 'exists_in_signup',
	EXISTS_IN_USER: 'exists_in_user',
	SOMETHING_WENT_WRONG: 'something_went_wrong',
	WRONG_EMAIL_OR_PASSWORD: 'error',
	EXHAUSTED: 'code_exhausted',
	UPC_FORMAT: 'invalid upc format',
	CODE_INVALID: 'This code is invalid - the bank identifier does not exist',
	DOES_NOT_MATCH: 'does not match',
	SALE_START_SLA:
		"Sales start date must be no earlier than 1 days from today's date",
	SALE_START_NOT_SLA:
		"Sales start date must be no earlier than 7 days from today's date",
	SALE_START_2_DAYS:
		"Sales start date must be no earlier than 2 days from today's date",
	SALE_START_3_DAYS:
		"Sales start date must be no earlier than 3 days from today's date",
};
