// Core
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';

// UI
import { FormInput } from 'components/Form/FormInput';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';

// Icons
import add_box_line from 'images/add_box_line.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import trash from 'images/trash.svg';

// Styles
import styles from './Outlets.module.css';

const useStyles = makeStyles({
	removeBeforeElement: {
		'&.MuiAccordion-root:before': {
			display: 'none',
		},
	},
});

const Outlets = ({
	artist,
	handleAddOutlet,
	onDeleteOutlet,
	changeFieldOutlet,
	outletsArr,
	errors,
	expanded,
	setExpanded,
	addOutletDisabled,
	disabledOutlets,
}) => {
	const classes = useStyles();

	const handleChange = (index) => (event, isExpanded) => {
		setExpanded(isExpanded ? index : null);
	};

	const artistOutletsArrIds = () => {
		return artist.outlets.map((item) => item.id);
	};

	return (
		<div className={styles.generalContainer}>
			<div className={styles.outletsBlock}>
				{disabledOutlets[0] !== 'all' && artist.outlets && (
					<span className={styles.outletsTitle}>
						<FormattedMessage id={'rod.release.create.step.submit.outlets'} />
					</span>
				)}
				{disabledOutlets[0] !== 'all' && (
					<button
						className={
							addOutletDisabled
								? `${styles.addOutlet} ${styles.disabled}`
								: styles.addOutlet
						}
						onClick={handleAddOutlet}
						disabled={addOutletDisabled}
					>
						<img
							src={add_box_line}
							alt="add outlet"
							className={styles.outletsBtns}
						/>
						<div className={styles.addOutletTitle}>
							<FormattedMessage id={'rod.release.create.add_outlets.title'} />
						</div>
					</button>
				)}
			</div>
			{artist.outlets && artist.outlets.length > 0 && (
				<div className={styles.outletsContainer}>
					{artist.outlets.map((outlet, index) => (
						<Accordion
							key={index}
							expanded={expanded === index}
							onChange={handleChange(index)}
							className={classes.removeBeforeElement}
							sx={{
								border: '1px solid var(--color-light-grey)',
								boxShadow: 'none',
								margin: '0 !important',
							}}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls={`${index}bh-content`}
								id={`${index}bh-header`}
								sx={{
									height: '48px',
									borderTopWidth: 0,
								}}
							>
								<Typography
									sx={{
										font: 'var(--gilroy-Medium-18-140) !important',
										fontWeight: '700 !important',
										color: 'var(--color-dark-grey) !important',
									}}
								>
									{outlet.outlet || ''}
								</Typography>
								{disabledOutlets[0] !== 'all' &&
									!disabledOutlets.includes(outlet.outlet_id) && (
										<button
											className={styles.deleteBtn}
											onClick={() => onDeleteOutlet(outlet.id)}
										>
											<img src={trash} alt="trash" />
										</button>
									)}
							</AccordionSummary>
							<AccordionDetails>
								<div className={styles.outletsForm}>
									<div className={styles.outlet}>
										<FormInput
											type={'MuiAutocompleteArtistOutlets'}
											name={'outlets'}
											onChange={changeFieldOutlet(index)}
											errors={errors}
											data={artist}
											pseudonumOutletIndex={index}
											outletsArr={outletsArr.filter(
												(item) => !artistOutletsArrIds().includes(item.id)
											)}
											label={<FormattedMessage id={'rod.pseudonyms.outlet'} />}
											style={{
												pointerEvents: artist.outlets[index].outlet
													? 'all'
													: 'none',
											}}
											disabled={
												disabledOutlets[0] === 'all' ||
												disabledOutlets.includes(outlet.outlet_id)
											}
										/>
										{Object.keys(errors).includes('outlet_id') && (
											<span className={styles.errorHelper}>
												{!outlet.outlet_id && (
													<FormattedMessage
														id={`rod.error.${errors.outlet_id[0].rule}`}
													/>
												)}
											</span>
										)}
									</div>
									<div className={styles.outletId}>
										<FormInput
											type="muiInput"
											name={'id_outlet_user'}
											onChange={changeFieldOutlet(index)}
											required={artist.outlets[index].outlet}
											errors={errors}
											data={artist.outlets[index]}
											pseudonumOutletIndex={index}
											label={'ID'}
											style={{
												pointerEvents: artist.outlets[index].outlet
													? 'all'
													: 'none',
											}}
											disabled={
												disabledOutlets[0] === 'all' ||
												disabledOutlets.includes(outlet.outlet_id)
											}
										/>
										{Object.keys(errors).includes('id_outlet_user') &&
											!Object.values(errors).some(
												(item) =>
													item[0].rule === 'unique_value' &&
													item[0].id === outlet.outlet_id
											) &&
											artist.outlets[index].outlet && (
												<span className={styles.errorHelper}>
													{!outlet.id_outlet_user && (
														<FormattedMessage
															id={`rod.error.${errors.id_outlet_user[0].rule}`}
														/>
													)}
												</span>
											)}
										{Object.keys(errors) &&
											Object.keys(errors).some((key) =>
												key.includes('id_outlet_user')
											) &&
											Object.values(errors).some(
												(item) =>
													item[0].rule === 'unique_value' &&
													item[0].id === outlet.outlet_id
											) && (
												<span
													className={styles.errorHelper}
													style={{ bottom: '-35px' }}
												>
													<FormattedMessage
														id={'rod.error.outlets.id_outlet_user.unique_value'}
													/>
												</span>
											)}
									</div>
								</div>
							</AccordionDetails>
						</Accordion>
					))}
				</div>
			)}

			{Object.keys(errors) &&
				Object.keys(errors).includes('outlets') &&
				errors.outlets.length &&
				errors.outlets[0] === 'unique_value' && (
					<span className={styles.errorHelper} style={{ bottom: '-30px' }}>
						<FormattedMessage id={'rod.error.outlets.unique_value'} />
					</span>
				)}
		</div>
	);
};

export default Outlets;
