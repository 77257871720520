// Core
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'react-grid-system';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getHelpMessage } from 'messages/helper';

// Context
import { LangContext } from 'contexts/LangContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { capitalizeWords } from 'utils';

// UI
import { Button } from 'components/Buttons/Button';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { EditButton, InfoButton } from 'components/Buttons';
import { RadioSexGroup } from 'components/Radio';
import { Label } from 'components/Labels/Label';
import { FormInput } from 'components/Form/FormInput';

// Styles
import styles from '../general.module.css';

const SoleProprietor = (props) => {
	const { lang } = useContext(LangContext);
	const { showModal } = useContext(UIContext);
	const firstColErrors = useRef(null);
	const secondColErrors = useRef(null);
	const firstColRefs = ['last_name', 'first_name', 'patronymic'];
	const secondColRefs = [
		'birth_date',
		'birth_place',
		'national_id_number',
		'itin',
	];

	const [errors, setErrors] = useState({});
	const [personal, setPersonal] = useState({});
	const [isEdit, setIsEdit] = useState(false);
	const [patronymic, setPatronymic] = useState(true);
	const [isChangedSmth, setIsChangedSmth] = useState(false);

	useEffect(() => {
		setPersonal({ ...props.data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const errorFields = Object.keys(errors);
		if (errorFields.length > 0) {
			if (errorFields.some((error) => firstColRefs.includes(error))) {
				if (firstColErrors?.current) {
					firstColErrors.current.scrollIntoView({ behavior: 'smooth' });
				}
			}
			if (errorFields.some((error) => secondColRefs.includes(error))) {
				if (secondColErrors?.current) {
					secondColErrors.current.scrollIntoView({ behavior: 'smooth' });
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errors]);

	const {
		rootContext: { countries },
	} = props;

	const message = DOMPurify.sanitize(
		...getHelpMessage(lang, 'personal_info_PE')
	);

	const changeField = (field) => (value) => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		// Clear the error for the specific field
		setErrors((prevErrors) => {
			const newErrors = { ...prevErrors };
			delete newErrors[field];
			return newErrors;
		});

		if (
			field === 'first_name' ||
			field === 'last_name' ||
			field === 'patronymic'
		) {
			setPersonal((prev) => ({ ...prev, [field]: capitalizeWords(value) }));
		} else {
			setPersonal((prev) => ({ ...prev, [field]: value }));
		}
	};

	useEffect(() => {
		setErrors(props.errors);
		setIsEdit(Object.keys(props.errors).length > 0);
		setIsChangedSmth(Object.keys(props.errors).length > 0);
	}, [props.errors]);

	const saveHandler = (e) => {
		e.preventDefault();

		setIsChangedSmth(false);
		props.saveHandler(personal);
	};

	const handleEditToggle = () => {
		setIsEdit(!isEdit);
	};

	const patronymicCheckboxHandler = () => {
		if (!isChangedSmth) {
			setIsChangedSmth(true);
		}

		if (patronymic) {
			setPersonal((prev) => ({ ...prev, patronymic: '' }));
		}

		setPatronymic(!patronymic);
	};

	return (
		<>
			{personal && (
				<div className={styles.form}>
					<div className={styles.editButton}>
						{!isEdit && <EditButton handleEditToggle={handleEditToggle} />}
					</div>
					<Row>
						<Col sm={8} className={styles.header}>
							<InfoButton
								onClick={showModal({
									text: parse(message),
								})}
							>
								<Label
									className={styles.title}
									text={<FormattedMessage id={'rod.account.personal.title'} />}
									font="--gilroy-Medium-40"
									display="inline"
									black
								/>
							</InfoButton>
							<Label
								className={styles.subTitle}
								text={<FormattedMessage id={'rod.form.required_title'} />}
								font="--gilroy-Medium-18"
							/>
						</Col>
					</Row>
					<div
						className={styles.container}
						style={{
							pointerEvents: isEdit ? 'all' : 'none',
							opacity: isEdit ? '1' : '0.6',
							userSelect: isEdit ? 'default' : 'none',
						}}
					>
						<div ref={firstColErrors}>
							<RadioSexGroup
								changeField={changeField}
								errors={errors}
								personal={personal}
							/>
							<FormattedMessage id={'rod.field.lastname'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'last_name'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.firstname'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'first_name'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.patronymic'}>
								{(placeholder) => (
									<>
										<FormInput
											placeholder={placeholder}
											name={'patronymic'}
											onChange={changeField}
											errors={errors}
											data={personal}
											disabled={!patronymic || !isEdit}
											style={{
												pointerEvents: patronymic ? 'all' : 'none',
												opacity: patronymic ? '1' : '0.6',
											}}
										/>
										<Checkbox
											text={<FormattedMessage id={'rod.field.no_patronymic'} />}
											className={
												errors.patronymic
													? styles.checkboxWithError
													: styles.checkbox
											}
											checked={!patronymic}
											onChange={patronymicCheckboxHandler}
										/>
									</>
								)}
							</FormattedMessage>
						</div>
						<div ref={secondColErrors}>
							<FormattedMessage id={'rod.field.birthdate'}>
								{(placeholder) => (
									<FormInput
										type="date"
										name={'birth_date'}
										placeholder={placeholder}
										onChange={changeField}
										errors={errors}
										data={personal}
										isEdit={isEdit}
										hideCross={true}
										required
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.registration_address'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'birth_place'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.livingPlace}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.passport'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'national_id_number'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.tax_number'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'itin'}
										onChange={changeField}
										errors={errors}
										data={personal}
										required={
											window.location.href.includes('.ru') ||
											window.location.href.includes('broma16ru-stage')
												? true
												: false
										}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>

					<Row>
						<Col sm={8} className={styles.header} style={{ marginTop: '40px' }}>
							<InfoButton
								onClick={showModal({
									text: parse(message),
								})}
							>
								<Label
									className={styles.title}
									text={
										<FormattedMessage
											id={'rod.account.personal_entrepreneur.title'}
										/>
									}
									font="--gilroy-Medium-40"
									display="inline"
									black
								/>
							</InfoButton>
							<Label
								className={styles.subTitle}
								text={<FormattedMessage id={'rod.form.required_title'} />}
								font="--gilroy-Medium-18"
							/>
						</Col>
					</Row>
					<div
						className={styles.container}
						style={{
							pointerEvents: isEdit ? 'all' : 'none',
							opacity: isEdit ? '1' : '0.6',
							userSelect: isEdit ? 'default' : 'none',
						}}
					>
						<div>
							<FormattedMessage id={'rod.field.personal_entrepreneur.name'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.name.helper'}
											/>
										}
										name={'title'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.personal_entrepreneur.signer'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.signer.helper'}
											/>
										}
										name={'behalf'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.widthCorrecting}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.signer_short_name'}
							>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={
													'rod.field.personal_entrepreneur.signer_short_name.helper'
												}
											/>
										}
										name={'behalf_short'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
										className={styles.widthCorrecting}
									/>
								)}
							</FormattedMessage>
							{isEdit && (
								<div className={styles.save_btn} style={{ marginTop: '62px' }}>
									<Button
										text={<FormattedMessage id={'rod.action.save'} />}
										variant={'primary'}
										onClick={saveHandler}
										disabled={!isEdit || !isChangedSmth}
										style={{
											pointerEvents: isChangedSmth ? 'all' : 'none',
										}}
									/>
								</div>
							)}
						</div>
						<div>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.registration_date'}
							>
								{(placeholder) => (
									<FormInput
										type="date"
										placeholder={placeholder}
										name={'company_registration_date'}
										onChange={changeField}
										errors={errors}
										data={personal}
										isEdit={isEdit}
										hideCross={true}
										maxDate={new Date()}
										required
									/>
								)}
							</FormattedMessage>
							<div>
								<FormattedMessage
									id={'rod.field.personal_entrepreneur.registration_place'}
								>
									{(placeholder) => (
										<FormInput
											type="country"
											countries={countries}
											placeholder={placeholder}
											name={'country_id'}
											onChange={changeField}
											errors={errors}
											required
											data={personal}
											className={styles.country}
										/>
									)}
								</FormattedMessage>
							</div>

							<FormattedMessage
								id={'rod.field.personal_entrepreneur.registration_number'}
							>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'company_registration_number'}
										onChange={changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(SoleProprietor);
