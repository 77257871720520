export const getRecordingPerformers = (data) => {
	if (
		data.recording_performers &&
		Array.isArray(data.recording_performers) &&
		data.recording_performers.length
	) {
		const performersTitles = data.recording_performers.map(
			(item) => item.title
		);
		return (
			<span title={performersTitles.join(', ')}>
				{performersTitles.join(', ')}
			</span>
		);
	}

	return <span>-</span>;
};
