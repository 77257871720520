// Core
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { withAuth, withLang, withRoot, withUI } from 'hocs';

// UI
import { Auth, Lang } from 'components';
import { Label } from 'components/Labels';
import { Menu } from 'components/Menu';
import { NavBar } from 'containers/NavBar';
import { Notify } from 'material-design/NotificationView';
import LoadPage from 'material-design/TreatiesPage/LoadPage/LoadPage';
import { Save, Bell } from 'images';

// Constants
import modalTypes from 'constants/modalTypes';

// Icons
import logo from 'images/logo.svg';
import logo_dgtal from './../../partner/dgtal/logo_dgtal.png';
import logo_mts from './../../partner/mts/logo_mts.svg';
import bell_dot from 'images/bell_dot.svg';

// Styles
import styles from './Header.module.css';

class Header extends Component {
	static propTypes = {};
	static defaultProps = {};
	static displayName = 'Header';
	constructor(props) {
		super(props);
		this.state = {
			modalNotifyActive: false,
			modalLoadPageActive: false,
			modalRepertoireActive: false,
			modalInstructionActive: false,
			showMessages: false,
		};
		this.setModalNotifyActive = this.setModalNotifyActive.bind(this);
		this.setModalLoadPageActive = this.setModalLoadPageActive.bind(this);
	}

	componentDidMount() {
		this.getNotifications(true);
	}

	componentDidUpdate(prevProps, prevState) {
		const prevId = prevProps.authContext.accountId;
		const currentId = this.props.authContext.accountId;
		this.getNotifications(false, +prevId === +currentId ? false : true);
	}

	setModalNotifyActive(active) {
		if (!active) {
			this.getNotifications(false, true);
		}
		this.setState({
			modalNotifyActive: active,
		});
	}

	getNotifications(firstTime, shouldBeUpdated) {
		try {
			const {
				authContext: { accountId, isJoin },
				rootContext: { getUserNotifications },
				UIContext: { setNotificationsSent, notificationsSent },
			} = this.props;

			if (
				isJoin &&
				+accountId &&
				(firstTime || shouldBeUpdated || notificationsSent.includes(+accountId))
			) {
				getUserNotifications(+accountId).then((res) => {
					const notReadData = res.find(
						(notif) => notif.read_user === 0 || notif.read_user === '0'
					);
					this.setState({
						showMessages: notReadData ? true : false,
					});
					if (!firstTime) {
						setNotificationsSent(
							notificationsSent.filter((notific) => notific !== +accountId)
						);
					}
				});
			}
		} catch (err) {
			console.error(err);
		}
	}

	setModalLoadPageActive(active) {
		this.setState({
			modalLoadPageActive: active,
		});
	}

	render() {
		const {
			UIContext: {
				isShowBackNavi,
				isShowUserMenu,
				isTitle,
				backTitle,
				showUserMenu,
				backTargetUrl,
				backButtonCallback,
				showModal,
				isShowNavAndButtons,
				isAdminLayoutShow,
				isExpiredContract,
			},
			authContext: { isAccountLoading },
		} = this.props;

		const isJoin = this.props.authContext.isJoin;
		const isAuth = this.props.authContext.isAuth;
		const isNoContract = localStorage.getItem('no_contract');

		return (
			<>
				<header className={styles.Header}>
					{isShowBackNavi && (
						<div className={styles.Back}>
							{backTargetUrl && (
								<Link to={backTargetUrl}>
									<svg
										width="41"
										height="15"
										viewBox="0 0 41 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.292893 6.79289C-0.0976295 7.18341 -0.0976296 7.81658 0.292892 8.2071L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41422 7.5L8.07107 1.84314C8.46159 1.45262 8.46159 0.819454 8.07107 0.428929C7.68054 0.0384049 7.04738 0.0384048 6.65685 0.428929L0.292893 6.79289ZM41 6.5L1 6.5L1 8.5L41 8.5L41 6.5Z"
											fill="black"
										/>
									</svg>
									<FormattedMessage id={backTitle} />
								</Link>
							)}
							{backButtonCallback && (
								<button onClick={() => backButtonCallback()}>
									<svg
										width="41"
										height="15"
										viewBox="0 0 41 15"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M0.292893 6.79289C-0.0976295 7.18341 -0.0976296 7.81658 0.292892 8.2071L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41422 7.5L8.07107 1.84314C8.46159 1.45262 8.46159 0.819454 8.07107 0.428929C7.68054 0.0384049 7.04738 0.0384048 6.65685 0.428929L0.292893 6.79289ZM41 6.5L1 6.5L1 8.5L41 8.5L41 6.5Z"
											fill="black"
										/>
									</svg>
									<FormattedMessage id={'rod.action.back'} />
								</button>
							)}
						</div>
					)}

					{isTitle && isShowBackNavi && (
						<Label font="--gilroy-Medium-24-140p" className={styles.Title}>
							<FormattedMessage id={isTitle} />
						</Label>
					)}

					{!isAdminLayoutShow ? (
						<>
							{isJoin && isShowNavAndButtons && !isNoContract && (
								<ul className={styles.Header__mainButtonsWrapper}>
									<li>
										<div
											className={
												isJoin
													? styles.langTray_wrapper_desktop
													: styles.langTray_wrapper_mobile
											}
										>
											<ul className={styles.Tray}>
												<li>
													{!isAdminLayoutShow &&
														this.props.langContext.isShowLang && (
															<Lang className={styles.Lang} />
														)}
												</li>
											</ul>
										</div>
									</li>
									{process.env.REACT_APP_CLIENT !== 'mts' &&
										process.env.REACT_APP_CLIENT !== 'dgtal' && (
											<li>
												<button
													className={`${styles.Header__mainButton} ${styles.RightMargin}`}
													disabled={isExpiredContract}
													style={
														isExpiredContract
															? { opacity: '0.5', cursor: 'default' }
															: {}
													}
													onClick={this.setModalLoadPageActive}
												>
													<div className={styles.Header__imgWrapper}>
														<Save />
													</div>
												</button>
											</li>
										)}
									<li>
										<button
											className={styles.Header__mainButton}
											onClick={this.setModalNotifyActive}
										>
											<div className={styles.Header__imgWrapper}>
												{!this.state.showMessages ? (
													<Bell />
												) : (
													<div className={styles.bell__wrapper}>
														<Bell />
														<img
															src={bell_dot}
															className={styles.bell__dot}
															alt=""
														/>
													</div>
												)}
											</div>
										</button>
									</li>
								</ul>
							)}
						</>
					) : (
						<p className={styles.ListTitle}>
							<FormattedMessage
								id={
									window.location.href.includes('admin/v2/articles')
										? `rod.header.admin.articles`
										: `rod.header.admin`
								}
							/>
						</p>
					)}

					{!isShowBackNavi && process.env.REACT_APP_CLIENT === 'broma' && (
						<div
							style={{ backgroundImage: `url(${logo})` }}
							className={styles.Image}
						/>
					)}
					{!isShowBackNavi && process.env.REACT_APP_CLIENT === 'dgtal' && (
						<div
							style={{ backgroundImage: `url(${logo_dgtal})` }}
							className={styles.Image_dgtal}
						/>
					)}
					{!isShowBackNavi && process.env.REACT_APP_CLIENT === 'mts' && (
						<div
							style={{ backgroundImage: `url(${logo_mts})` }}
							className={styles.Image}
						/>
					)}

					{!isJoin && (
						<div
							className={
								isJoin
									? styles.langTray_wrapper_desktop
									: styles.langTray_wrapper_mobile
							}
						>
							<ul className={styles.Tray}>
								<li>
									{!isAdminLayoutShow && this.props.langContext.isShowLang && (
										<Lang className={styles.Lang} />
									)}
								</li>
							</ul>
						</div>
					)}
					{isJoin && (
						<div className={styles.menuTray_wrapper}>
							<Label font="--gilroy-Medium-16-19" white>
								<FormattedMessage id={`rod.header.menu`} />
							</Label>
							<div
								className={styles.menuTray_burger}
								onClick={showModal(
									{
										children: <NavBar authContext={this.props.authContext} />,
									},
									modalTypes.BURGER_MODAL
								)}
							></div>
						</div>
					)}

					<div className={styles.Header__mainButtonsWrapperAdmin}>
						{isAdminLayoutShow && (
							<div
								className={
									isJoin
										? styles.langTray_wrapper_desktop
										: styles.langTray_wrapper_mobile
								}
							>
								<ul className={styles.Tray}>
									<li>
										{isAdminLayoutShow && this.props.langContext.isShowLang && (
											<Lang className={styles.Lang} />
										)}
									</li>
								</ul>
							</div>
						)}

						{isAuth && (
							<Auth
								className={styles.User}
								style={
									isAccountLoading
										? { opacity: 0.5, cursor: 'not-allowed' }
										: {}
								}
								onClick={isAccountLoading ? () => {} : () => showUserMenu()}
							/>
						)}
					</div>
				</header>
				{isJoin && (
					<div
						className={
							isShowUserMenu
								? `${styles.langSelectPhoneMode_wrapper} ${styles.menu__open}`
								: styles.langSelectPhoneMode_wrapper
						}
					>
						{isJoin && (
							<ul className={styles.Header__mainButtonsPhoneWrapper}>
								<button
									className={styles.Header__mainButton}
									onClick={this.setModalLoadPageActive}
								>
									<div className={styles.Header__imgWrapper}>
										<Save
											className={
												isShowUserMenu ? styles.Header__saveOpen : null
											}
										/>
									</div>
								</button>
								<button
									className={styles.Header__mainButton}
									onClick={this.setModalNotifyActive}
								>
									<div className={styles.Header__imgWrapper}>
										{!this.state.showMessages ? (
											<Bell
												className={
													isShowUserMenu ? styles.Header__bellOpen : null
												}
											/>
										) : (
											<div className={styles.bell__wrapper}>
												<Bell
													className={
														isShowUserMenu ? styles.Header__bellOpen : null
													}
												/>

												<img
													src={bell_dot}
													className={styles.bell__dot}
													alt=""
												/>
											</div>
										)}
									</div>
								</button>
							</ul>
						)}

						<ul className={styles.langSelectPhone_ul}>
							<li>
								{process.env.REACT_APP_CLIENT !== 'dgtal' &&
									!isAdminLayoutShow && <Lang className={styles.Lang} />}
							</li>
						</ul>

						{isJoin && (
							<Auth
								className={styles.UserPhone}
								style={
									isAccountLoading
										? { opacity: 0.5, cursor: 'not-allowed' }
										: {}
								}
								onClick={isAccountLoading ? () => {} : () => showUserMenu()}
							/>
						)}
					</div>
				)}
				{this.state.modalNotifyActive && (
					<Notify
						active={this.state.modalNotifyActive}
						setActive={this.setModalNotifyActive}
					/>
				)}
				{this.state.modalLoadPageActive && (
					<LoadPage
						active={this.state.modalLoadPageActive}
						setActive={this.setModalLoadPageActive}
					/>
				)}
				{isShowUserMenu && <Menu className="" />}
			</>
		);
	}
}

export default compose(withUI, withAuth, withLang, withRoot)(Header);
