export const getAuthor = (item) => {
	if (item.type === 'automatic') return '';

	if (item.performed_by_title) {
		return (
			<div>
				<span>{item.performed_by_title}</span>
			</div>
		);
	} else {
		return '';
	}
};
