import { useContext, useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-grid-system';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

// UI
import { Button } from 'components/Buttons';
import { Checkbox } from 'components/Checkboxes';
import { Divider } from '@mui/material';
import { FormInput } from 'components/Form';
import { Label } from 'components/Labels';
import Loading from 'components/Loading';
import Table from 'components/Table/Table';
import ReportsStatement from './ReportsStatement/ReportsStatement';

// Constants
import accountTypes from 'constants/accountTypes';
import paymentTypes from 'constants/paymentTypes';

// Contexts
import { RootContext } from 'contexts/RootContext';
import { UIContext } from 'contexts/UIContext';

// Utils
import { generateUniqueId } from './utils/generate-unique-id';
import { personalDataHelper } from './utils/personal-data.util';
import { checkPaymentStatus } from '../utils/check-payment-status.util';

// Styles
import styles from './DataConfirm.module.css';
import { NewHelpInfo } from 'components';

const DataConfirm = (props) => {
	const checkboxRef = useRef(null);
	const history = useHistory();

	const {
		showTitle,
		showBackNavi,
		hideBackNavi,
		hideTitle,
		showModal,
	} = useContext(UIContext);

	const {
		getCountryById,
		getAccountPaymentData,
		getAccountPersonalData,
		getContractSignTypes,
		createInvoice,
		getAccountContactsData,
		getAccountPayments,
		getAccountPaymentsReports,
		reportFiles,
	} = useContext(RootContext);

	const [isLoading, setIsLoading] = useState(false);
	const [buttonLoading, setIsButtonLoading] = useState(false);
	const [reports, setReports] = useState();
	const [personalData, setPersonalData] = useState(null);
	const [paymentInfo, setPaymentInfo] = useState(null);
	const [contactInfo, setContactInfo] = useState(null);
	const [personalSectionData, setPersonalSectionData] = useState([]);
	const [paymentsSectionData, setPaymentsSectionData] = useState([]);
	const [taxFormSectionData, setTaxFormSectionData] = useState([]);
	const [contractSignTypes, setContractSignTypes] = useState([]);
	const [invoiceIdGenerateType, setInvoiceIdGenerateType] = useState({
		type: 'auto',
	});
	const [invoiceId, setInvoiceId] = useState(null);
	const [signType, setSignType] = useState(null);
	const [checked, setChecked] = useState(null);
	const [errors, setErrors] = useState({});
	const [showDownloadError, setShowDownloadError] = useState(false);

	const accountId = localStorage.getItem('accountId');

	const fetchPaymentsReports = async () => {
		const data = await getAccountPaymentsReports(accountId);
		if (data) {
			setReports(data);
		}
	};

	useEffect(() => {
		const fetchUserData = async () => {
			const { data } = await getAccountPayments(accountId, 1, 1);
			if (data.length) {
				const notCompletedPayment = data.find((payment) => {
					return checkPaymentStatus(payment.status);
				});
				if (notCompletedPayment) {
					history.push(
						`../reports/sign?id=${notCompletedPayment.id}&status=${notCompletedPayment.status}`
					);
					return;
				}
			}
			const { data: paymentData } = await getAccountPaymentData(accountId);
			const { data: userData } = await getAccountPersonalData(accountId);
			const { data: contactData } = await getAccountContactsData(accountId);
			const signTypes = await getContractSignTypes();
			setContractSignTypes(signTypes);
			setPersonalData(userData.data);
			setPaymentInfo(paymentData.data);
			setContactInfo(contactData.data);
			setIsLoading(false);
		};

		setIsLoading(true);
		fetchUserData();
		fetchPaymentsReports();
		showTitle('rod.navbar.withdraw');
		showBackNavi(`/accounts/${accountId}/reports`);

		return () => {
			hideBackNavi();
			hideTitle();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (personalData && contactInfo) {
			if (personalData?.business_type !== accountTypes.PERSONAL) {
				setPersonalSectionData([
					...personalDataHelper(
						personalData.business_type,
						personalData,
						getCountryById
					),
					{
						title: (
							<FormattedHTMLMessage id={'rod.field.registration_address'} />
						),
						value: contactInfo.address,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.zip'} />,
						value: contactInfo.zip_code,
					},
					{
						title: <FormattedHTMLMessage id={'rod.admin.account.city'} />,
						value: contactInfo.city,
					},
				]);
			} else {
				setPersonalSectionData([
					...personalDataHelper(
						personalData.business_type,
						personalData,
						getCountryById
					),
					{
						title: (
							<FormattedHTMLMessage id={'rod.field.registration_address'} />
						),
						value: contactInfo.address,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.zip'} />,
						value: contactInfo.zip_code,
					},
					{
						title: <FormattedHTMLMessage id={'rod.admin.account.city'} />,
						value: contactInfo.city,
					},
				]);
			}

			let id = 'rod.field.personal_entrepreneur.tax_number';
			if (personalData?.business_type === accountTypes.COMPANY) {
				id = 'rod.field.company.tax_number_register';
			}
			setTaxFormSectionData([
				{
					title: <FormattedHTMLMessage id={id} />,
					value: personalData.itin ?? '-',
				},
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personalData, contactInfo]);

	useEffect(() => {
		if (paymentInfo) {
			if (paymentInfo.type === paymentTypes.PAYPAL) {
				setPaymentsSectionData([
					{
						title: <FormattedHTMLMessage id={'rod.field.paypal_account'} />,
						value: paymentInfo.paypal,
					},
				]);
			} else {
				setPaymentsSectionData([
					{
						title: (
							<FormattedHTMLMessage
								id={
									personalData?.business_type === 'individual'
										? 'rod.field.beneficiary_name.individual'
										: 'rod.field.beneficiary_name'
								}
							/>
						),
						value: paymentInfo.bank_account_title,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_account_name'} />,
						value: paymentInfo.bank_account_number,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.swift_code'} />,
						value: paymentInfo.bank_account_swift,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_name'} />,
						value: paymentInfo.bank_title,
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_country'} />,
						value: getCountryById(paymentInfo.bank_country_id, false),
					},
					{
						title: <FormattedHTMLMessage id={'rod.field.bank_address'} />,
						value: paymentInfo.bank_address,
					},
				]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentInfo]);

	const handleConfirm = async () => {
		const fd = new FormData();

		reportFiles.forEach((file, index) => {
			fd.append(`file[${index}]`, file);
		});

		if (invoiceIdGenerateType.type === 'auto') {
			setIsButtonLoading(true);

			try {
				const data = await createInvoice(accountId, signType, null, fd);
				setIsButtonLoading(false);
				history.push(`/accounts/${accountId}/reports/sign?id=${data.id}`);
			} catch (data) {
				console.error(data.response.data.errors);
				setErrors(data.response.data.errors);
			} finally {
				setIsButtonLoading(false);
			}
		}

		if (invoiceIdGenerateType.type === 'manual') {
			showModal(
				{
					text: (
						<FormattedHTMLMessage
							id={'rod.release.create.step.tracks.title.help'}
						/>
					),
					invoiceId: invoiceId,
					signType: signType,
					fd: fd,
					createInvoice: createInvoice,
				},
				'invoice_number_modal'
			)();
		}
	};

	const renderSection = (title, data) => {
		return (
			<Table
				className={styles.Table}
				noBorder
				title={title}
				body={data.map((item, index) => (
					<tr key={index}>
						<td className="title">{item.title}</td>
						<td className="value">{item.value}</td>
					</tr>
				))}
			/>
		);
	};

	const changeSignType = (field) => (e) => {
		setSignType(e);
	};

	const changeField = (field) => (value) => {
		setInvoiceIdGenerateType({ type: value });

		if (value === 'auto') setInvoiceId(null);
		if (value === 'manual') {
			const invoiceId = generateUniqueId();
			setInvoiceId(invoiceId);
		}
	};

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<div className={styles.form}>
				<Container fluid>
					<Row>
						<div className={styles.page}>
							<Label
								text={
									<FormattedHTMLMessage id={'rod.account.data.confirm_title'} />
								}
								black
								font="--gilroy-Medium-40"
							/>
							<div className={styles.helpInfo}>
								<NewHelpInfo text="rod.reports_page.confirm_data_tab.help" />
							</div>
							{personalData?.business_type === accountTypes.PERSONAL &&
								renderSection(
									<FormattedHTMLMessage
										id={'rod.account.data.confirm.account'}
									/>,
									personalSectionData
								)}
							{personalData?.business_type ===
								accountTypes.PERSONAL_ENTREPRENEUR &&
								renderSection(
									<FormattedHTMLMessage
										id={'rod.account.data.confirm.personal_entrepreneur'}
									/>,
									personalSectionData
								)}
							{personalData?.business_type === accountTypes.COMPANY &&
								renderSection(
									<FormattedHTMLMessage
										id={'rod.account.data.confirm.company'}
									/>,
									personalSectionData
								)}
							{renderSection(
								<FormattedHTMLMessage
									id={'rod.account.data.confirm.tax_form'}
								/>,
								taxFormSectionData
							)}
							{renderSection(
								<FormattedHTMLMessage
									id={'rod.account.data.confirm.payments'}
								/>,
								paymentsSectionData
							)}
							<div className={styles.controls}>
								<Label
									text={
										<FormattedHTMLMessage
											id={'rod.account.data.confirm.sign_type'}
										/>
									}
									black
									font="--gilroy-Bold-24"
								/>
								{contractSignTypes.length > 0 && (
									<FormattedMessage
										id={'rod.field.contracts.sign_type.placeholder'}
									>
										{(placeholder) => (
											<FormInput
												type={'dropdown'}
												placeholder={placeholder}
												name={'sign'}
												className="input"
												defaultEmpty
												onChange={changeSignType}
												errors={{}}
												data={{}}
												required
												items={contractSignTypes}
												getItem={(item) => (
													<FormattedHTMLMessage
														id={`rod.field.contracts.sign_types.${item.title}`}
													/>
												)}
												getListItem={(item) => (
													<FormattedHTMLMessage
														id={`rod.field.contracts.sign_types.${item.title}`}
													/>
												)}
												getItemValue={(item) => item.id}
											/>
										)}
									</FormattedMessage>
								)}
								<Label
									text={
										<FormattedHTMLMessage
											id={'rod.account.data.generating_invoice_number'}
										/>
									}
									black
									font="--gilroy-Bold-24"
								/>
								<div className={styles.radio}>
									<FormInput
										type={'radio'}
										name={'type'}
										onChange={changeField}
										errors={''}
										data={invoiceIdGenerateType}
										horizontal
										items={[
											{
												text: (
													<FormattedMessage
														id={
															'rod.account.data.generating_invoice_number.auto'
														}
													/>
												),
												value: 'auto',
											},
											{
												text: (
													<FormattedMessage
														id={
															'rod.account.data.generating_invoice_number.manual'
														}
													/>
												),
												value: 'manual',
											},
										]}
									/>
								</div>
								{reports && reports.length > 0 && (
									<ReportsStatement
										reports={reports}
										fetchPaymentsReports={fetchPaymentsReports}
										errors={errors}
										setErrors={setErrors}
										showDownloadError={showDownloadError}
										setShowDownloadError={setShowDownloadError}
									/>
								)}
								<Checkbox
									className={styles.сheckbox}
									required
									ref={checkboxRef}
									onChange={() => setChecked(checkboxRef.current.checked)}
								>
									<FormattedMessage id={'rod.field.confirm.checkbox'} />
								</Checkbox>
							</div>
						</div>
					</Row>
				</Container>
			</div>
			<Divider
				sx={{
					marginLeft: '-30px',
					marginBottom: '16px',
					marginRight: '-30px',
				}}
			/>
			<div className={styles.submit__page}>
				<button
					className={styles.prev_btn}
					onClick={() => history.push(`/accounts/${accountId}/reports/check`)}
				>
					<FormattedMessage id={'rod.release.create.step.handler_back'} />
				</button>
				<Button
					text={<FormattedHTMLMessage id={'rod.account.data.confirm.create'} />}
					variant={'primary'}
					disabled={!(signType && checked) || buttonLoading}
					onClick={handleConfirm}
				/>
			</div>
		</>
	);
};

export default DataConfirm;
