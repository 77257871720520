// Core
import React, { useEffect, useState } from 'react';
import { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';

// UI
import { Loading } from 'components';
import { Modal, Shade } from './Posed';
import { Container } from 'components/Grid';
import BaseModal from '../BaseModal';
import { Button } from 'components/Buttons/Button';
import { InfoBar } from 'components';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from '@mui/material';
import { SwitchSave } from 'material-design/MuiStyled/MuiStyled';
import NotifyGroups from './NotifyGroups/NotifyGroups';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './NotifyEditModal.module.css';

const NotifyEditModal = ({
	accountId,
	lang,
	getUserNotificationsSettings,
	putUserNotificationsSettings,
	onClose,
}) => {
	const [loading, setLoading] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [interfaceExpanded, setInterfaceExpanded] = useState(false);
	const [emailExpanded, setEmailExpanded] = useState(false);
	const [accountSettings, setAccountSettings] = useState([]);
	const [userSettings, setUserSettings] = useState([]);

	useEffect(() => {
		setIsVisible(true);

		setLoading(true);
		getUserNotificationsSettings(accountId)
			.then((res) => {
				setAccountSettings(res.account);
				setUserSettings(res.user);

				if (res.account.some((item) => item.notification)) {
					setInterfaceExpanded(true);
				}

				if (res.user.some((item) => item.mail)) {
					setEmailExpanded(true);
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const willUnmount = (e) => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const handleSwitched = (field, value) => {
		if (field === 'account') {
			setInterfaceExpanded(value);

			const newAccountSettings = accountSettings.map((item) => {
				return { ...item, notification: value };
			});
			setAccountSettings(newAccountSettings);
		} else {
			setEmailExpanded(value);

			const newUserSettings = userSettings.map((item) => {
				return { ...item, mail: value };
			});
			setUserSettings(newUserSettings);
		}
	};

	const handleChange = (dataType, id) => {
		if (dataType === 'account') {
			const newAccountSettings = accountSettings.map((item) => {
				if (item.id === id) {
					return { ...item, notification: !item.notification };
				}
				return item;
			});
			if (newAccountSettings.every((item) => !item.notification)) {
				setInterfaceExpanded(false);
			}
			setAccountSettings(newAccountSettings);
		} else {
			const newUserSettings = userSettings.map((item) => {
				if (item.id === id) {
					return { ...item, mail: !item.mail };
				}
				return item;
			});
			if (newUserSettings.every((item) => !item.mail)) {
				setEmailExpanded(false);
			}
			setUserSettings(newUserSettings);
		}
	};

	const submitHandler = () => {
		const payload = { account: accountSettings, user: userSettings };
		putUserNotificationsSettings(accountId, payload)
			.then((e) => willUnmount(e))
			.catch((error) => console.error(error));
	};

	return (
		<BaseModal className={styles.modal}>
			<PoseGroup>
				{isVisible && [
					<Shade key="shade" className={styles.overlay} />,
					<Modal
						key="modal"
						style={{
							marginLeft: 'auto',
						}}
					>
						<Container className={styles.container} fluid>
							<div>
								<div
									md={12 - 4}
									onClick={(e) => {
										willUnmount(e);
									}}
								/>
								<div className={styles.bodyContainer}>
									{loading ? (
										<Loading />
									) : (
										<div className={styles.bodyWrapper}>
											<div className={styles.bodyTopWrapper}>
												<div className={styles.bodyTitle}>
													<button
														onClick={(e) => {
															willUnmount(e);
														}}
														className={styles.backBtn}
													>
														<FormattedMessage id={'rod.action.back'} />
													</button>
												</div>
												<CloseIcon
													onClick={(e) => {
														willUnmount(e);
													}}
													style={{ cursor: 'pointer' }}
												/>
											</div>
											<div className={styles.bodyHeader}>
												<span className={styles.bodyTitle}>
													<FormattedMessage
														id={'rod.notification.settings.title'}
													/>
												</span>
											</div>
											<div>
												<InfoBar
													text="rod.notification.settings.info.text"
													marginBottom="20px"
												/>
											</div>
											<div className={styles.cardsContainer}>
												{accountSettings.length > 0 && (
													<Accordion
														key={'account'}
														expanded={interfaceExpanded}
														sx={{
															border: '1px solid var(--color-light-grey)',
															boxShadow: 'none',
															margin: '0 !important',
														}}
													>
														<AccordionSummary
															sx={{
																height: '48px',
																padding: '0 12px',
																borderTopWidth: 0,
															}}
														>
															<div className={styles.headerContainer}>
																<Typography
																	sx={{
																		font:
																			'var(--gilroy-Medium-18-24) !important',
																		fontWeight: '700 !important',
																		letterSpacing: '1px',
																		color: 'var(--color-dark-grey) !important',
																	}}
																>
																	<FormattedMessage
																		id={
																			'rod.notification.settings.accordion.interface.title'
																		}
																	/>
																</Typography>
																<div className={styles.switcher}>
																	<SwitchSave
																		checked={interfaceExpanded}
																		onChange={(_, c) =>
																			handleSwitched('account', c)
																		}
																	/>
																</div>
															</div>
														</AccordionSummary>
														<AccordionDetails
															sx={{
																display: 'flex',
																flexDirection: 'column',
																gap: '14px',
																paddingTop: 0,
															}}
														>
															<NotifyGroups
																dataArray={accountSettings}
																lang={lang}
																dataType="account"
																handleChange={handleChange}
															/>
														</AccordionDetails>
													</Accordion>
												)}
												{userSettings.length > 0 && (
													<Accordion
														key={'user'}
														expanded={emailExpanded}
														sx={{
															border: '1px solid var(--color-light-grey)',
															boxShadow: 'none',
															margin: '0 !important',
															'&:before': {
																content: 'none',
															},
														}}
													>
														<AccordionSummary
															sx={{
																height: '48px',
																padding: '0 12px',
																borderTopWidth: 0,
															}}
														>
															<div className={styles.headerContainer}>
																<Typography
																	sx={{
																		font:
																			'var(--gilroy-Medium-18-24) !important',
																		fontWeight: '700 !important',
																		letterSpacing: '1px',
																		color: 'var(--color-dark-grey) !important',
																	}}
																>
																	<FormattedMessage
																		id={
																			'rod.notification.settings.accordion.email.title'
																		}
																	/>
																</Typography>
																<div className={styles.switcher}>
																	<SwitchSave
																		checked={emailExpanded}
																		onChange={(_, c) =>
																			handleSwitched('user', c)
																		}
																	/>
																</div>
															</div>
														</AccordionSummary>
														<AccordionDetails>
															<NotifyGroups
																dataArray={userSettings}
																lang={lang}
																dataType="user"
																handleChange={handleChange}
															/>
														</AccordionDetails>
													</Accordion>
												)}
											</div>
											<Button
												text={<FormattedMessage id={'rod.action.save'} />}
												variant={'primary'}
												onClick={submitHandler}
												className={styles.saveBtn}
												disabled={false}
											/>
										</div>
									)}
								</div>
							</div>
						</Container>
					</Modal>,
				]}
			</PoseGroup>
		</BaseModal>
	);
};

export default NotifyEditModal;
