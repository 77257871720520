// Core
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames';

// UI
import Calendar from 'react-calendar';
import { Input } from 'components/Inputs/Input';
import { InputWithFloatingLabel } from '../InputWithFloatingLabel';

// Icons
import calendarImg from 'images/calendar_icon.svg';
import calendarImgActive from 'images/calendar_icon_active.svg';

// Styles
import styles from './DatePickerInputForAssets.module.css';

const DatePickerInputForAssets = ({
	focus = false,
	type = 'text',
	onChange = () => {},
	errors,
	value: propValue = '',
	placeholder,
	helper,
	className,
	forwardRef,
	name,
	showRequired,
	floatingLabel,
	lang,
	minDate,
	maxDate,
	...props
}) => {
	const [date, setDate] = useState(propValue || '');
	const [isOpen, setIsOpen] = useState(false);

	const inputRef = useRef(null);
	const containerRef = useRef(null);
	const combinedInputRef = forwardRef || inputRef;

	// Calculate the minDateValue dynamically
	const minDateValue = minDate ? minDate : null;

	useEffect(() => {
		if (focus) {
			combinedInputRef.current.focus();
		}
	}, [focus, combinedInputRef]);

	useEffect(() => {
		if (propValue) {
			const formattedDate = moment(propValue, 'YYYY-MM-DD HH:mm:ss').format(
				'DD.MM.YYYY'
			);
			setDate(formattedDate);
		}
	}, [propValue]);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				setIsOpen(false);
			}
		};

		if (isOpen) {
			window.addEventListener('mousedown', handleClickOutside);
		} else {
			window.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen]);

	const handleFocus = () => setIsOpen(true);

	const handleClear = () => {
		setDate('');
		onChange('');
	};

	const handleChange = (value) => {
		// If value is an object (from Calendar selection)
		if (value === '') {
			handleClear();
			return;
		}

		if (typeof value === 'object') {
			try {
				const formattedDate = moment(value).format('YYYY-MM-DD');
				setDate(moment(value).format('DD.MM.YYYY'));
				onChange(formattedDate);
				setIsOpen(false);
			} catch (error) {
				console.error('Invalid date object: ', error);
			}
			return;
		}

		// Handle manual input changes
		let newValue = value.replace(/\./g, '');
		const lastChar = newValue.slice(-1);

		// Allow only numeric values and restrict the length
		if (!/[0-9]/.test(lastChar) || lastChar === ' ') return;
		if (newValue.length > 8) return;

		// Format the date based on the length of input
		if (newValue.length === 3 || newValue.length === 4) {
			newValue = newValue.replace(/(\d{1,2})(\d{1,2})/g, '$1.$2');
		} else if (newValue.length > 4) {
			newValue = newValue.replace(/(\d{1,2})(\d{1,2})(\d{1,4})/g, '$1.$2.$3');
		}

		// Update input value
		setDate(newValue);

		// If the input contains a complete date (dd.MM.yyyy), format it and pass it back
		if (newValue.length === 10) {
			const isValidDate = moment(newValue, 'DD.MM.YYYY', true).isValid();

			if (isValidDate) {
				const formattedDate = newValue.replace(
					/(\d{1,2})\.(\d{1,2})\.(\d{1,4})/g,
					'$3-$2-$1'
				);
				onChange(formattedDate);
			} else {
				console.error('Invalid date format entered: ', newValue);
			}
		}
	};

	const classes = classnames(className, errors && styles.Error, styles.Input);

	return (
		<div className={classes} ref={containerRef}>
			<div className={styles.InputWrapper}>
				{floatingLabel ? (
					<InputWithFloatingLabel
						{...props}
						errors={errors}
						value={
							date && date.length === 10
								? date.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1')
								: date
						}
						placeholder={`${placeholder}${
							showRequired && props.required ? '*' : ''
						}`}
						onChange={handleChange}
						onFocus={handleFocus}
						className={styles.dateInput}
					/>
				) : (
					<Input
						{...props}
						errors={errors}
						value={
							date && date.length === 10
								? date.replace(/(\d{1,4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1')
								: date
						}
						placeholder={`${placeholder}${
							showRequired && props.required ? '*' : ''
						}`}
						onChange={handleChange}
						onFocus={handleFocus}
						className={styles.dateInput}
					/>
				)}

				<img
					className={styles.Icon}
					src={date ? calendarImgActive : calendarImg}
					alt="calendar"
					onClick={handleFocus}
				/>
			</div>
			<input type="hidden" ref={combinedInputRef} name={name} value={date} />
			{isOpen && (
				<div className={styles.CalendarWrapper}>
					<Calendar
						locale={lang}
						onChange={handleChange}
						minDate={minDateValue}
						maxDate={maxDate}
						value={propValue ? new Date(propValue) : null}
					/>
				</div>
			)}
		</div>
	);
};

export default DatePickerInputForAssets;
