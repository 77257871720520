// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Context
import { LangContext } from 'contexts/LangContext';

// Icons
import linkIcon from 'images/playlist/link-to-platform.svg';
import spotifyIcon from 'images/playlist/spotifyIcon.png';
import appleIcon from 'images/playlist/appleMusicIcon.png';

// Styles
import styles from './PlaylistItem.module.css';

function PlaylistItem({ item, idx, currentPage }) {
	const { lang } = useContext(LangContext);

	const platformIcons = {
		Spotify: spotifyIcon,
		'Apple Music': appleIcon,
	};

	return (
		<>
			<li className={styles.playlistListItem}>
				<div className={styles.playlistInfoWrapper}>
					<div className={styles.playlistNumber}>
						{idx + 1 + (currentPage - 1) * 10}
					</div>
					<img
						className={styles.playlistImg}
						src={item.image}
						alt="playlist image"
					/>
					<div className={styles.playlistName}>{item.title}</div>
				</div>
				<div className={styles.auditionsQuantity}>{item.streams}</div>
				<div className={styles.subscribersQuantity}>{item.followers}</div>
				<div className={styles.platformInfo}>
					<img
						className={styles.platformIcon}
						src={platformIcons[item.outlet] || null}
						alt={`${item.outlet} icon`}
					/>
					<div className={styles.platformName}>{item.outlet}</div>
				</div>
				<a href={item.uri} target="_blank" className={styles.linkToPlatform}>
					<img src={linkIcon} />
				</a>
			</li>
			<li key={idx} className={styles.playlistListItem__Adaptive}>
				<div className={styles.playlistInfoWrapper__Adaptive}>
					<div className={styles.playlistNumber}>
						{idx + 1 + (currentPage - 1) * 10}
					</div>
					<img
						className={styles.playlistImg}
						src={item.image}
						alt="playlist image"
					/>
					<div className={styles.playlistName}>{item.title}</div>
				</div>
				<p className={styles.headerInfoStreams}>
					<FormattedMessage id={'rod.statistic.playlists.playlist_streams'} />
				</p>
				<p className={styles.headerInfoFollowers}>
					<FormattedMessage id={'rod.statistic.playlists.playlist_followers'} />
				</p>
				<p className={styles.headerInfoPlatform}>
					<FormattedMessage id={'rod.statistic.playlists.playlist_platform'} />
				</p>
				<p
					className={
						lang === 'en' ? styles.headerInfoLinkEn : styles.headerInfoLink
					}
				>
					<FormattedMessage id={'rod.statistic.playlists.playlist_link'} />
				</p>
				<div className={styles.auditionsQuantity}>{item.streams}</div>
				<div className={styles.subscribersQuantity}>{item.followers}</div>
				<div className={styles.platformInfo}>
					<img
						className={styles.platformIcon}
						src={platformIcons[item.outlet] || null}
						alt={`${item.outlet} icon`}
					/>
					<div className={styles.platformName}>{item.outlet}</div>
				</div>
				<a href={item.uri} target="_blank" className={styles.linkToPlatform}>
					<img src={linkIcon} />
				</a>
			</li>
		</>
	);
}

export default PlaylistItem;
