// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './InfoBar.module.css';

const InfoBar = ({ text, height, marginBottom }) => {
	return (
		<div
			className={styles.container}
			style={{ height: height || 'auto', marginBottom: marginBottom || '48px' }}
		>
			<FormattedMessage id={text} />
		</div>
	);
};

export default InfoBar;
