export const toGeneralFormat = (data) => {
	return data.map((track) => {
		return {
			...track,
			is_instrumental:
				track.is_instrumental === null || track.is_instrumental === 0
					? false
					: track.is_instrumental === 1
					? true
					: track.is_instrumental,
			lyrics:
				track.lyrics === '<p><br></p>' || track.lyrics === undefined
					? ''
					: track.lyrics,
		};
	});
};
