export const getFeaturedPerformers = (data) => {
	if (data && (typeof data === 'string' || typeof data === 'number')) {
		return [{ name: data.toString() }, ''];
	} else if (Array.isArray(data)) {
		const formattedPerformers = data.map((item) => {
			if (typeof item === 'string' || typeof item === 'number') {
				return { name: item.toString() };
			}
			return item;
		});
		return [...formattedPerformers, ''];
	} else {
		return [''];
	}
};
