// Core
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { RootContext } from 'contexts/RootContext';

// Utils
import { getJSONParse } from 'utils';
import { stylingDigit } from 'utils';
import { getStartEndDatesForOneYear } from 'material-design/Statistic/StatisticCategory/utils/getStartEndDatesForOneYear';

// UI
import { TopTracksSkeleton } from 'components';

// Styles
import styles from './TopTracksMain.module.css';

const TopTracksMain = () => {
	const history = useHistory();
	const {
		getAccountStatisticOutlets,
		getAccountStatisticTopTracks,
	} = useContext(RootContext);
	const { accountId } = useContext(AuthContext);

	// eslint-disable-next-line no-unused-vars
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32).toISOString(),
		end_date: new Date(new Date().getTime() - 86400000 * 2).toISOString(),
	});
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [outletsCodes, setOutletsCodes] = useState([]);
	const [topTracks, setTopTracks] = useState([]);

	const startEndDates = getStartEndDatesForOneYear(statisticData, false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const outletsRes = await getAccountStatisticOutlets();
				const outletsData = outletsRes?.data?.data;
				const outletsCodes = outletsData?.map((outlet) => outlet.code);
				setOutletsCodes(outletsCodes);
				const topTracksRes = await getAccountStatisticTopTracks(
					false,
					accountId,
					1,
					startEndDates[0],
					startEndDates[1],
					outletsCodes,
					10,
					[],
					[]
				);
				const topTracksData = topTracksRes?.data?.data;
				setTopTracks(topTracksData?.recordings);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setLoading(false);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading ? (
				<TopTracksSkeleton />
			) : (
				<>
					{topTracks && topTracks.length > 0 && (
						<>
							<div className={styles.topTracksTitleMain}>
								<span>
									<FormattedHTMLMessage id="rod.statistic.top_tracks.main" />
								</span>
							</div>
							<div>
								<table className={styles.topTracksTable}>
									<thead className={styles.topTracksTable__header}>
										<tr>
											<td> </td>
											<td>
												<FormattedHTMLMessage
													id={'rod.statistic.top.track.title'}
												/>
											</td>
											<td>
												<FormattedHTMLMessage
													id={'rod.statistic.top.performer'}
												/>
											</td>
											<td>
												<FormattedHTMLMessage id={'rod.statistic.top.album'} />
											</td>
											<td>
												<FormattedHTMLMessage
													id={'rod.statistic.top.streams'}
												/>
											</td>
										</tr>
									</thead>
									<tbody className={styles.topTracksTable__body}>
										{topTracks.length > 0 &&
											topTracks.map((item, index) => (
												<tr
													key={index}
													className={styles.tableRow}
													onClick={() =>
														history.push(`/repertoire/recordings/${item.id}`)
													}
												>
													<td
														className={`${styles.topTracksTable__item}, ${styles.topTracksTable__itemNumber}`}
													>
														{index + 1}
													</td>
													<td className={styles.topTracksTable__item}>
														{item.raw_title}
														<span className={styles.topTracksTable__fullName}>
															{item.raw_title}
														</span>
													</td>
													<td className={styles.topTracksTable__item}>
														{item.performers
															? getJSONParse(item.performers)
															: '-'}
													</td>
													<td className={styles.topTracksTable__item}>
														{item.release_name}
													</td>
													<td className={styles.topTracksTable__item}>
														{stylingDigit(item.streams)}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</>
					)}
				</>
			)}
		</>
	);
};

export default TopTracksMain;
