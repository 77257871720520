// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import { Checkbox } from 'components/Checkboxes/Checkbox';

// Styles
import styles from '../NotifyEditModal.module.css';

const NotifyGroups = ({ dataArray, lang, dataType, handleChange }) => {
	return (
		<>
			<div>
				<span className={styles.notificationBlockTitle}>
					<FormattedMessage
						id={'rod.notification.settings.accordion.reports_payments.title'}
					/>
				</span>
				<div className={styles.notificationBlockBody}>
					{dataArray.length > 0 &&
						dataArray
							.filter(
								(item) =>
									item.code.includes('payment.') ||
									item.code.includes('report.')
							)
							.map((item) => (
								<Checkbox
									key={item.code}
									checked={item.notification ? item.notification : item.mail}
									text={lang === 'en' ? item.title_en : item.title_ru}
									onChange={() => handleChange(dataType, item.id)}
									className={styles.checkbox}
								/>
							))}
				</div>
			</div>
			<div>
				<span className={styles.notificationBlockTitle}>
					<FormattedMessage
						id={'rod.notification.settings.accordion.repertoire.title'}
					/>
				</span>
				<div className={styles.notificationBlockBody}>
					{dataArray.length > 0 &&
						dataArray
							.filter((item) => item.code.includes('repertoire.'))
							.map((item) => (
								<Checkbox
									key={item.code}
									checked={item.notification ? item.notification : item.mail}
									text={lang === 'en' ? item.title_en : item.title_ru}
									onChange={() => handleChange(dataType, item.id)}
									className={styles.checkbox}
								/>
							))}
				</div>
			</div>
			<div>
				<span className={styles.notificationBlockTitle}>
					<FormattedMessage
						id={'rod.notification.settings.accordion.other.title'}
					/>
				</span>
				<div className={styles.notificationBlockBody}>
					{dataArray.length > 0 &&
						dataArray
							.filter(
								(item) =>
									!item.code.includes('payment.') &&
									!item.code.includes('report.') &&
									!item.code.includes('repertoire.')
							)
							.map((item) => (
								<Checkbox
									key={item.code}
									checked={item.notification ? item.notification : item.mail}
									text={lang === 'en' ? item.title_en : item.title_ru}
									onChange={() => handleChange(dataType, item.id)}
									className={styles.checkbox}
								/>
							))}
				</div>
			</div>
		</>
	);
};

export default NotifyGroups;
