// Core
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

// Media
import headImgBroma from 'images/serviceNotAvailable/Broma/Head.png';
import faceImgBroma from 'images/serviceNotAvailable/Broma/Face.svg';

import headImgDgtal from 'images/serviceNotAvailable/Dgtal/Head.png';
import faceImgDgtal from 'images/serviceNotAvailable/Dgtal/Face.svg';

import headImgMts from 'images/serviceNotAvailable/MTS/Head.png';
import faceImgMts from 'images/serviceNotAvailable/MTS/Face.svg';

// Styles
import styles from './ServiceNotAvailable.module.css';

const imageMap = {
	broma: {
	  head: headImgBroma,
	  face: faceImgBroma,
	},
	dgtal: {
	  head: headImgDgtal,
	  face: faceImgDgtal,
	},
	mts: {
	  head: headImgMts,
	  face: faceImgMts,
	},
  };

  const currentClient = process.env.REACT_APP_CLIENT;
  const images = imageMap[currentClient] || imageMap['broma']; 


const ServiceNotAvailable = () => {
	const faceRef = useRef(null);
	const handleMouseMove = (e) => {
		const { clientX, clientY } = e;
		const face = faceRef.current.parentElement.getBoundingClientRect();
		const isMouseOverface =
			clientX >= face.left &&
			clientX <= face.right &&
			clientY >= face.top &&
			clientY <= face.bottom;
		if (isMouseOverface) {
			faceRef.current.style.transform = 'translate(0, 0)';
		} else {
			const faceCenterX = face.left + face.width / 2;
			const faceCenterY = face.top + face.height / 2;
			const angle = Math.atan2(clientY - faceCenterY, clientX - faceCenterX);
			const faceRadius = 45;
			faceRef.current.style.transform = `translate(${
				Math.cos(angle) * faceRadius
			}px, ${Math.sin(angle) * faceRadius}px)`;
		}
	};
	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, []);
	return (
		<div className={styles.wrapper}>
			<main className={styles.main}>
				<div className={styles.imgWrapper}>
					<img className={styles.headImg} src={images.head} alt="Head" />
					<div className={styles.faceImgWrapper}>
						<img
							className={styles.faceImg}
							ref={faceRef}
							src={images.face}
							alt="Face"
							style={{ width: '63px', height: '39px', position: 'absolute' }}
						/>
					</div>
				</div>
				<div className={styles.text}>
					<h1 className={styles.title}>
						<FormattedMessage id={'rod.service_not_available.title'} />
					</h1>
					<p className={styles.description}>
						<FormattedMessage id={'rod.service_not_available.sub_title'} />
					</p>
				</div>
			</main>
		</div>
	);
};
export default ServiceNotAvailable;
