/*
 *   Copyright (C) Heaven11 IPDB INC. LTD - All Rights Reserved
 *   * Unauthorized copying of this file, via any medium is strictly prohibited
 *   * Proprietary and confidential
 *   * Written by Leonid Artemev <me@artemev.it>, 25/05/2020, 20:02
 */
// Core
import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { FormattedHTMLMessage } from 'react-intl';

// UI
import { CloseButton } from 'components/Buttons';
import { Label } from 'components/Labels/Label';
import BaseModal from './BaseModal';

// Styles
import styles from './ModalModern.module.css';

const Modal = posed.div({
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			default: { duration: 300 },
		},
	},
	exit: {
		opacity: 0,
		transition: { duration: 250 },
	},
});

const Shade = posed.div({
	enter: { opacity: 0.5 },
	exit: { opacity: 0 },
});

class FullScreenModalConfirm extends Component {
	constructor() {
		super();
		this.state = { isVisible: false, isTitle: false };
	}

	componentDidMount() {
		this.setState({
			isVisible: true,
			onCloseFn: this.props.onClose,
			onActionFn: this.props.onAction,
		});
	}

	willUnmount(e) {
		this.setState({
			isVisible: false,
		});

		setTimeout(() => {
			this.state.onCloseFn();
		}, 300);
	}

	makeAction(e) {
		if (this.state.onActionFn) {
			if (this.props.accountId && this.props.pseudonymId) {
				this.state.onActionFn(this.props.accountId, this.props.pseudonymId);
			} else {
				this.state.onActionFn();
			}
		}
		this.willUnmount();
	}

	render() {
		const {
			title,
			text,
			onAction,
			confirmBtnTxt,
			declineBtnTxt,
			children,
			withClose,
		} = this.props;

		return (
			<BaseModal className={styles.modal}>
				<PoseGroup>
					{this.state.isVisible && [
						<Shade key="shade" className={styles.overlay} />,

						<Modal
							key="modal"
							style={{
								marginLeft: 'auto',
							}}
						>
							<div className={styles.contentWrapper}>
								{withClose && (
									<div className={styles.close_button}>
										<CloseButton onClick={(e) => this.willUnmount(e)} />
									</div>
								)}
								<div className={styles.content}>
									{title && <Label className={styles.title} text={title} />}
									{text ? (
										<div className={styles.paragraph}>
											<FormattedHTMLMessage id={text.props.id}>
												{(pureText) => (
													<div
														className={styles.Paragraph}
														style={{
															paddingTop: pureText.includes('<h1>')
																? '116px'
																: '0',
														}}
													>
														{text}
													</div>
												)}
											</FormattedHTMLMessage>
										</div>
									) : (
										children
									)}
								</div>

								{confirmBtnTxt && declineBtnTxt ? (
									<div className={styles.twoBtnsWrapper}>
										<button
											className={styles.btn}
											onClick={(e) => this.makeAction(e)}
										>
											{confirmBtnTxt}
										</button>
										<button
											className={styles.declineBtn}
											onClick={(e) => {
												this.willUnmount(e);
											}}
										>
											{declineBtnTxt}
										</button>
									</div>
								) : (
									<>
										{confirmBtnTxt && (
											<button
												className={styles.confirmBtn}
												onClick={(e) => {
													onAction && this.makeAction(e);
													this.willUnmount(e);
												}}
											>
												{confirmBtnTxt}
											</button>
										)}
									</>
								)}
							</div>
						</Modal>,
					]}
				</PoseGroup>
			</BaseModal>
		);
	}
}

export default FullScreenModalConfirm;
