// Core
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import { compose } from 'recompose';
import moment from 'moment';
import DOMPurify from 'dompurify';

// Utils
import { getHelpMessage } from '../../../../../messages/helper';
import { showError } from 'validators/showError';
import { withAuth, withLang, withRoot, withUI } from 'hocs';
import update from 'immutability-helper';
import { capitalizeWords } from 'utils';

// Constants
import { isForCom } from 'constants/urlConstants';

// UI
import { Loading } from 'components';
import { Checkbox } from 'components/Checkboxes/Checkbox';
import { Button } from 'components/Buttons/Button';
import { FormInput } from 'components/Form/FormInput';
import { Label } from 'components/Labels/Label';
import { InfoButton } from 'components/Buttons';
import { Wizard } from 'components/Wizard';
import accountTypes from 'constants/accountTypes';

// Styles
import styles from 'pages/account/Create/steps/03-Info/Info.module.css';

class Info extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.form = React.createRef();
		const stepsMessageId = 'rod.account.create.step';

		this.steps = [
			{
				id: 1,
				status: 'active',
				title: (
					<FormattedMessage
						id={`${stepsMessageId}_2_1_${props.personalData.business_type}.title`}
					/>
				),
			},
			{
				id: 2,
				status: 'default',
				title: <FormattedMessage id={`${stepsMessageId}_2_2.title`} />,
			},
			{
				id: 3,
				status: 'default',
				title: <FormattedMessage id={`${stepsMessageId}_2_3.title`} />,
			},
			{
				id: 4,
				status: 'default',
				title: <FormattedMessage id={`${stepsMessageId}_2_4.title`} />,
			},
			{
				id: 5,
				status: 'default',
				title: <FormattedMessage id={`${stepsMessageId}_2_5.title`} />,
			},
		];

		this.state = {
			loading: true,
			personal: {
				sex: 'male',
			},
			patronymic: true,
			errFromInput: false,
		};
	}

	componentDidMount() {
		this.update();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.id !== this.props.id) {
			this.update();
		}
	}

	update() {
		const {
			authContext: { changeWidget, setIsAccountLoading },
			rootContext: { getAccountPersonalData },
		} = this.props;

		setIsAccountLoading(true);
		changeWidget();
		getAccountPersonalData(this.props.id)
			.then((res) => {
				res = res.data.data;
				const {
					business_type,
					completion_step,
					moderation_status,
					sex,
					...rest
				} = res;
				this.setState(
					update(this.state, {
						personal: { $set: { sex: sex ? sex : 'male', ...rest } },
						loading: { $set: false },
					})
				);
				setIsAccountLoading(false);
			})
			.catch((error) => {
				console.error('Info - getAccountPersonalData error: ', error);
				console.error(
					'Info - getAccountPersonalData error.response: ',
					error.response
				);
				setIsAccountLoading(false);
			});
	}

	dateErrorCheck(errors, fieldName) {
		const err = showError(fieldName)({
			rule: 'date_format',
			value: 'YYYY-mm-dd',
		});

		this.setState({ errFromInput: true });
		errors[fieldName] = [
			{
				rule: 'date_format',
				value: 'YYYY-mm-dd',
			},
		];
		return err;
	}

	nextHandler = (e) => {
		e.preventDefault();
		const { errors } = this.props;
		const { business_type } = this.props.personalData;
		const {
			birth_date,
			national_id_issued_date,
			national_id_expiration_date,
		} = this.state.personal;

		if (business_type !== 'legal_entity') {
			if (
				(birth_date && !moment(birth_date).isValid()) ||
				(birth_date && birth_date.length < 10)
			) {
				return this.dateErrorCheck(errors, 'birth_date');
			}
			if (
				(national_id_issued_date &&
					!moment(national_id_issued_date).isValid()) ||
				(national_id_issued_date && national_id_issued_date.length < 10)
			) {
				return this.dateErrorCheck(errors, 'national_id_issued_date');
			}
			if (
				(national_id_expiration_date &&
					!moment(national_id_expiration_date).isValid()) ||
				(national_id_expiration_date && national_id_expiration_date.length < 10)
			) {
				return this.dateErrorCheck(errors, 'national_id_expiration_date');
			}
		}

		const {
			authContext: { getUserData },
		} = this.props;

		if (typeof this.props.onChange === 'function') {
			getUserData();

			switch (this.props.personalData.business_type) {
				case accountTypes.PERSONAL: {
					this.props.onChange({
						...this.state.personal,
						title: `${this.state.personal.first_name} ${this.state.personal.last_name}`,
						business_type: this.props.personalData.business_type,
					});
					break;
				}
				case accountTypes.PERSONAL_ENTREPRENEUR: {
					this.props.onChange({
						...this.state.personal,
						business_type: this.props.personalData.business_type,
					});
					break;
				}
				case accountTypes.COMPANY: {
					this.props.onChange({
						...this.state.personal,
						business_type: this.props.personalData.business_type,
					});
					break;
				}
				default: {
					console.error('unknown type');
				}
			}
		}
	};

	changeField = (field) => (value) => {
		if (field === 'company_registration_date') {
			if (this.props.errors?.company_registration_date)
				delete this.props.errors?.company_registration_date;
			this.setState({ errFromInput: false });
		}

		if (field === 'birth_date') {
			if (this.props.errors?.birth_date) delete this.props.errors?.birth_date;
			this.setState({ errFromInput: false });
		}

		if (field === 'national_id_issued_date') {
			if (this.props.errors?.national_id_issued_date)
				delete this.props.errors?.national_id_issued_date;
			this.setState({ errFromInput: false });
		}

		if (field === 'national_id_expiration_date') {
			if (this.props.errors?.national_id_expiration_date)
				delete this.props.errors?.national_id_expiration_date;
			this.setState({ errFromInput: false });
		}

		if (field === 'country_id') {
			this.setState(
				update(this.state, {
					personal: {
						[field]: { $set: value },
						company_registration_place: { $set: value },
					},
				})
			);
		} else if (
			field === 'first_name' ||
			field === 'last_name' ||
			field === 'patronymic' ||
			field === 'behalf' ||
			field === 'behalf_short'
		) {
			this.setState(
				update(this.state, {
					personal: {
						[field]: { $set: capitalizeWords(value) },
					},
				})
			);
		} else {
			this.setState(
				update(this.state, { personal: { [field]: { $set: value } } })
			);
		}
	};

	patronymicCheckboxHandler = () => {
		if (this.state.patronymic) {
			this.setState(
				update(this.state, { personal: { patronymic: { $set: '' } } })
			);
		}

		this.setState({ patronymic: !this.state.patronymic });
	};

	render() {
		const {
			personalData: { business_type },
			handleOpenPrevTabs,
		} = this.props;

		if (this.state.loading) {
			return <Loading non_margin={true} />;
		}
		return (
			<Container fluid className={styles.Page}>
				<Wizard
					steps={this.steps}
					className={styles.Wizard}
					handleOpenPrevTabs={handleOpenPrevTabs}
				/>
				<div className={styles.Form}>
					{business_type === accountTypes.PERSONAL &&
						this.renderPersonalFields()}
					{business_type === accountTypes.PERSONAL_ENTREPRENEUR &&
						this.renderPEFields()}
					{business_type === accountTypes.COMPANY && this.renderCompanyFields()}
					<Row className={styles.Controls}>{this.renderControls()}</Row>
				</div>
			</Container>
		);
	}

	renderPersonalFields() {
		const {
			rootContext: { countries },
			personalData: { business_type },
			UIContext: { showModal },
			langContext: { lang },
			errors,
		} = this.props;

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'personal_info')
		);
		const { personal } = this.state;

		return (
			<div>
				<Row>
					<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								className="header"
								text={<FormattedMessage id={'rod.account.personal.title'} />}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Label
							className="subheader"
							text={<FormattedMessage id={'rod.form.required_title'} />}
							font="--gilroy-Medium-18"
							black
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<div className={styles.Field}>
							<FormInput
								type={'radio'}
								name={'sex'}
								onChange={this.changeField}
								errors={errors}
								data={personal}
								horizontal
								items={[
									{
										text: <FormattedMessage id={'rod.field.sex.male'} />,
										value: 'male',
									},
									{
										text: <FormattedMessage id={'rod.field.sex.female'} />,
										value: 'female',
									},
								]}
							/>
						</div>
						<FormattedMessage id={'rod.field.firstname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'first_name'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.lastname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'last_name'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.patronymic'}>
							{(placeholder) => (
								<>
									<FormInput
										placeholder={placeholder}
										name={'patronymic'}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										disabled={!this.state.patronymic}
										style={{
											pointerEvents: this.state.patronymic ? 'all' : 'none',
											opacity: this.state.patronymic ? '1' : '0.6',
										}}
									/>
									<Checkbox
										text={<FormattedMessage id={'rod.field.no_patronymic'} />}
										className={
											errors.patronymic
												? styles.checkboxWithError
												: styles.checkbox
										}
										checked={!this.state.patronymic}
										onChange={this.patronymicCheckboxHandler}
									/>
								</>
							)}
						</FormattedMessage>

						<FormattedMessage id={'rod.field.birthdate'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'birth_date'}
									placeholder={placeholder}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.registration_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'birth_place'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
					</Col>
					<Col sm={5}>
						<FormattedMessage id={'rod.field.passport'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'national_id_number'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport_issuer'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'national_id_issued_by'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport_issue_date'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'national_id_issued_date'}
									placeholder={placeholder}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
									maxDate={new Date()}
								/>
							)}
						</FormattedMessage>
						{isForCom && (
							<FormattedMessage id={'rod.field.passport_expire_date'}>
								{(placeholder) => (
									<FormInput
										type="date"
										name={'national_id_expiration_date'}
										placeholder={placeholder}
										onChange={this.changeField}
										errors={errors}
										data={personal}
									/>
								)}
							</FormattedMessage>
						)}

						<FormattedMessage id={'rod.field.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={this.changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
						{business_type === accountTypes.PERSONAL && (
							<FormattedMessage id={'rod.field.residence_country'}>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'country_id'}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										className={styles.CountryInput}
									/>
								)}
							</FormattedMessage>
						)}
					</Col>
				</Row>
			</div>
		);
	}

	renderPEFields() {
		const {
			rootContext: { countries },
			personalData: { business_type },
			UIContext: { showModal },
			langContext: { lang },
			errors,
		} = this.props;

		const personalInfoMessage = DOMPurify.sanitize(
			...getHelpMessage(lang, 'personal_info')
		);
		const personalPeMessage = DOMPurify.sanitize(
			...getHelpMessage(lang, 'personal_info_PE')
		);

		const { personal } = this.state;

		return (
			<div>
				<Row>
					<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(personalInfoMessage),
							})}
						>
							<Label
								className="header"
								text={<FormattedMessage id={'rod.account.personal.title'} />}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>

						<Label
							className="subheader"
							text={<FormattedMessage id={'rod.form.required_title'} />}
							font="--gilroy-Medium-18"
							black
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<div className={styles.Field}>
							<FormInput
								type={'radio'}
								name={'sex'}
								onChange={this.changeField}
								errors={errors}
								data={personal}
								horizontal
								items={[
									{
										text: <FormattedMessage id={'rod.field.sex.male'} />,
										value: 'male',
									},
									{
										text: <FormattedMessage id={'rod.field.sex.female'} />,
										value: 'female',
									},
								]}
							/>
						</div>
						<FormattedMessage id={'rod.field.firstname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'first_name'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.lastname'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'last_name'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.patronymic'}>
							{(placeholder) => (
								<>
									<FormInput
										placeholder={placeholder}
										name={'patronymic'}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										style={{
											pointerEvents: this.state.patronymic ? 'all' : 'none',
											opacity: this.state.patronymic ? '1' : '0.6',
										}}
									/>
									<Checkbox
										text={<FormattedMessage id={'rod.field.no_patronymic'} />}
										className={
											errors.patronymic
												? styles.checkboxWithError
												: styles.checkbox
										}
										checked={!this.state.patronymic}
										onChange={this.patronymicCheckboxHandler}
									/>
								</>
							)}
						</FormattedMessage>
					</Col>
					<Col sm={5}>
						<FormattedMessage id={'rod.field.birthdate'}>
							{(placeholder) => (
								<FormInput
									type="date"
									name={'birth_date'}
									placeholder={placeholder}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.registration_address'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'birth_place'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.passport'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'national_id_number'}
									onChange={this.changeField}
									errors={errors}
									required
									data={personal}
								/>
							)}
						</FormattedMessage>
						<FormattedMessage id={'rod.field.tax_number'}>
							{(placeholder) => (
								<FormInput
									placeholder={placeholder}
									name={'itin'}
									onChange={this.changeField}
									errors={errors}
									data={personal}
									required={
										window.location.href.includes('.ru') ||
										window.location.href.includes('broma16ru-stage')
											? true
											: false
									}
								/>
							)}
						</FormattedMessage>
						{business_type === accountTypes.PERSONAL && (
							<FormattedMessage id={'rod.field.residence_country'}>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'country_id'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
										className={styles.CountryInput}
									/>
								)}
							</FormattedMessage>
						)}
					</Col>
				</Row>
				<Row>
					<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(personalPeMessage),
							})}
						>
							<Label
								text={
									<FormattedMessage
										id={'rod.account.personal_entrepreneur.title'}
									/>
								}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
					</Col>
				</Row>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<div className={styles.FieldsCol}>
							<FormattedMessage id={'rod.field.personal_entrepreneur.name'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.name.helper'}
											/>
										}
										name={'title'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.personal_entrepreneur.signer'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.personal_entrepreneur.signer.helper'}
											/>
										}
										name={'behalf'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.signer_short_name'}
							>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={
													'rod.field.personal_entrepreneur.signer_short_name.helper'
												}
											/>
										}
										name={'behalf_short'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
						</div>
					</Col>
					<Col sm={5}>
						<div className={styles.FieldsCol}>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.registration_date'}
							>
								{(placeholder) => (
									<FormInput
										type="date"
										placeholder={placeholder}
										name={'company_registration_date'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
										maxDate={new Date()}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.registration_place'}
							>
								{(placeholder) => (
									<FormInput
										type="country"
										countries={countries}
										placeholder={placeholder}
										name={'country_id'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
										className={styles.CountryInput}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage
								id={'rod.field.personal_entrepreneur.registration_number'}
							>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'company_registration_number'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

	renderCompanyFields() {
		const {
			errors,
			rootContext: { countries },
			UIContext: { showModal },
			langContext: { lang },
		} = this.props;

		const message = DOMPurify.sanitize(
			...getHelpMessage(lang, 'personal_info_Company')
		);

		const { personal } = this.state;

		const itemsLexeme = 'rod.field';
		return (
			<div>
				<Row>
					<Col sm={11} offset={{ sm: 1 }} className={styles.Header}>
						<InfoButton
							onClick={showModal({
								text: parse(message),
							})}
						>
							<Label
								text={<FormattedMessage id={'rod.account.company.title'} />}
								font="--gilroy-Medium-40"
								display="inline"
								black
							/>
						</InfoButton>
						<Label
							className="subheader"
							text={<FormattedMessage id={'rod.form.required_title'} />}
							font="--gilroy-Medium-18"
							black
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={5} offset={{ sm: 1 }}>
						<div className={styles.FieldsCol}>
							<FormattedMessage id={'rod.field.company.name'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage id={'rod.field.company.name.helper'} />
										}
										name={'title'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.signer'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.company.signer.helper'}
											/>
										}
										name={'behalf'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.signer_short_name'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										helper={
											<FormattedMessage
												id={'rod.field.company.signer_short_name.helper'}
											/>
										}
										name={'behalf_short'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.signer_position'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										name={'signature_position'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.signer_rights'}>
								{(placeholder) => (
									<FormInput
										type={'dropdown'}
										placeholder={placeholder}
										name={'acting_on'}
										defaultEmpty
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
										items={[
											{
												keyword: 'statute',
												lexeme: `${itemsLexeme}.statute`,
											},
											{
												keyword: 'agreement',
												lexeme: `${itemsLexeme}.agreement`,
											},
											{
												keyword: 'attorney',
												lexeme: `${itemsLexeme}.attorney`,
											},
										]}
										getItem={(item) => <FormattedMessage id={item.lexeme} />}
										getListItem={(item) => (
											<FormattedMessage id={item.lexeme} />
										)}
										getItemValue={(item) => item.keyword}
									/>
								)}
							</FormattedMessage>
							{personal.acting_on === 'attorney' && (
								<FormattedMessage id={'rod.field.company.attorney'}>
									{(placeholder) => (
										<FormInput
											placeholder={placeholder}
											name={'attorney'}
											onChange={this.changeField}
											errors={errors}
											required
											data={personal}
										/>
									)}
								</FormattedMessage>
							)}
						</div>
					</Col>
					<Col sm={5}>
						<div className={styles.FieldsCol}>
							<FormattedMessage id={'rod.field.company.registration_date'}>
								{(placeholder) => (
									<FormInput
										type="date"
										placeholder={placeholder}
										name={'company_registration_date'}
										onChange={this.changeField}
										errors={errors}
										required
										data={personal}
										maxDate={new Date()}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.registration_place'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										required
										type="country"
										countries={countries}
										name={'country_id'}
										className={styles.CountryInput}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.registration_number'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										required
										name={'company_registration_number'}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.tax_number'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										name={'itin'}
										required={
											window.location.href.includes('.ru') ||
											window.location.href.includes('broma16ru-stage')
												? true
												: false
										}
									/>
								)}
							</FormattedMessage>
							<FormattedMessage id={'rod.field.company.tax_number2'}>
								{(placeholder) => (
									<FormInput
										placeholder={placeholder}
										onChange={this.changeField}
										errors={errors}
										data={personal}
										required
										name={'company_vat_number'}
									/>
								)}
							</FormattedMessage>
						</div>
					</Col>
				</Row>
			</div>
		);
	}

	renderControls() {
		return (
			<React.Fragment>
				<Col md={5} xs={12} sm={12} offset={{ md: 1, xs: 1, sm: 1 }}>
					<div className={styles.FieldsCol}>
						<Button
							text={<FormattedMessage id={'rod.action.next'} />}
							variant={'primary'}
							onClick={this.nextHandler}
						/>
					</div>
				</Col>
			</React.Fragment>
		);
	}
}

export default compose(withRoot, withAuth, withLang, withUI)(Info);
