// Components
import {
	Authors,
	Confirm,
	Cover,
	Distribution,
	File,
	Lyrics,
	Release,
	Tracks,
} from '../steps';

// MaterialComponents
import releaseCompletionSteps from 'material-design/constants/releaseCompletionSteps';

export const renderStep = (
	cleanErrorsField,
	completionStep,
	setRelease,
	errors,
	checkDateErrors,
	setFiles,
	handlePrevStep,
	releaseTypeId,
	setTracks,
	setAuthors,
	setLyrics,
	setCover,
	setDISTRIBUTION,
	emptyTrackList,
	transferRelease
) => {
	switch (completionStep) {
		case releaseCompletionSteps.RELEASE:
			return (
				<Release
					cleanErrorsField={cleanErrorsField}
					onChange={setRelease}
					errors={errors}
					checkDateErrors={checkDateErrors}
				/>
			);
		case releaseCompletionSteps.FILE:
			return (
				<File
					errors={errors}
					onChange={setFiles}
					handlePrevStep={handlePrevStep}
					releaseTypeId={releaseTypeId}
				/>
			);
		case releaseCompletionSteps.TRACKS:
			return (
				<Tracks
					onChange={setTracks}
					handlePrevStep={handlePrevStep}
					cleanErrorsField={cleanErrorsField}
				/>
			);
		case releaseCompletionSteps.AUTHORS:
			return <Authors onChange={setAuthors} handlePrevStep={handlePrevStep} />;
		case releaseCompletionSteps.LYRICS:
			return <Lyrics onChange={setLyrics} handlePrevStep={handlePrevStep} />;
		case releaseCompletionSteps.COVER:
			return <Cover onChange={setCover} handlePrevStep={handlePrevStep} />;
		case releaseCompletionSteps.DISTRIBUTION:
			return (
				<Distribution
					errors={errors}
					onChange={setDISTRIBUTION}
					handlePrevStep={handlePrevStep}
					releaseTypeId={releaseTypeId}
					transferRelease={transferRelease}
				/>
			);
		case releaseCompletionSteps.CONFIRM:
			return (
				<Confirm
					handlePrevStep={handlePrevStep}
					emptyTrackList={emptyTrackList}
					releaseTypeId={releaseTypeId}
				/>
			);
		default:
			return;
	}
};
