// Core
import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Hooks
import { useContainerWidth } from './hooks/useContainerWidth';

// Utils
import { determineDisable } from './TopReleaseCard/utils/determineDisable';
import { getStartEndDatesForOneYear } from 'material-design/Statistic/StatisticCategory/utils/getStartEndDatesForOneYear';

// Context
import { RootContext } from 'contexts/RootContext';

// UI
import { Label } from 'components/Labels';
import Skeleton from './Skeleton/Skeleton';
import TopReleaseCard from './TopReleaseCard/TopReleaseCard';
import arrow_right from 'images/arrow-right-s-line.svg';

// Styles
import styles from './TopReleases.module.css';

const TopReleases = () => {
	const accountId = localStorage.getItem('accountId');
	const {
		getAccountStatisticTopReleases,
		getAccountStatisticTopReleasesInfo,
	} = useContext(RootContext);

	// eslint-disable-next-line no-unused-vars
	const [statisticData, setStatisticData] = useState({
		start_date: new Date(new Date().getTime() - 86400000 * 32).toISOString(),
		end_date: new Date(new Date().getTime() - 86400000 * 2).toISOString(),
	});
	const [loading, setLoading] = useState(true);
	const [releases, setReleases] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const [disable, setDisable] = useState(false);

	const startEndDates = getStartEndDatesForOneYear(statisticData, false);

	const cardsContainerRef = useRef(null);
	const cardWidth = 260;
	const gapBetweenCards = 20;

	const { cardsFit } = useContainerWidth(
		cardsContainerRef,
		cardWidth,
		gapBetweenCards,
		releases
	);

	const handlePrev = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + releases.length) % releases.length
		);
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % releases.length);
	};

	const handleResize = () => {
		setScreenWidth(window.innerWidth);
	};

	const getListens = (id, data) => {
		const releaseItem = data.find((item) => item.id === id);
		if (releaseItem) {
			return releaseItem.listens;
		} else return '-';
	};

	useEffect(() => {
		determineDisable(screenWidth, releases.length, currentIndex, setDisable);
	}, [currentIndex, disable, releases.length, screenWidth]);

	useEffect(() => {
		setScreenWidth(window.innerWidth);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		const fetchReleaseInfo = async () => {
			setLoading(true);

			try {
				if (accountId) {
					const topReleasesRes = await getAccountStatisticTopReleases(
						accountId,
						startEndDates[0],
						startEndDates[1]
					);

					const topReleases = topReleasesRes.data.data;
					if (topReleases.global) {
						const items = topReleases.global.map((release) => release.id);
						if (items.length) {
							const getReleasesInfo = await getAccountStatisticTopReleasesInfo(
								accountId,
								items
							);

							if (getReleasesInfo && getReleasesInfo.data) {
								const topReleasesInfo = getReleasesInfo.data.data.map(
									(release) => ({
										...release,
										cover: release.covers.path_xm ?? null,
										listens: getListens(release.id, topReleases.global),
									})
								);

								setReleases(topReleasesInfo);
							}
						}
					}
				}
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

		fetchReleaseInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId]);

	return (
		<>
			{loading ? (
				<Skeleton />
			) : (
				<>
					{releases && releases.length > 0 && (
						<div ref={cardsContainerRef} className={styles.container}>
							<div className={styles.controls}>
								<Label font="--gilroy-Medium-32" black>
									<FormattedMessage id="rod.statistic.top_releases.main" />
								</Label>

								{!cardsFit && (
									<div className={styles.arrows}>
										<button
											className={styles.arrowBtn}
											onClick={handlePrev}
											style={currentIndex === 0 ? { opacity: 0.5 } : {}}
											disabled={currentIndex === 0}
										>
											<img
												className={styles.arrowLeft}
												src={arrow_right}
												alt="left"
											/>
										</button>
										<button
											className={styles.arrowBtn}
											onClick={handleNext}
											style={
												currentIndex === releases.length - 1 || disable
													? { opacity: 0.5 }
													: {}
											}
											disabled={currentIndex === releases.length - 1 || disable}
										>
											<img src={arrow_right} alt="right" />
										</button>
									</div>
								)}
							</div>
							<div className={styles.carousel}>
								<div
									className={styles.cardsContainer}
									style={{
										transform: `translateX(-${
											currentIndex * (cardWidth + gapBetweenCards)
										}px)`,
									}}
								>
									{releases.length > 0 &&
										releases.map((release) => (
											<TopReleaseCard key={release.id} release={release} />
										))}
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default TopReleases;
