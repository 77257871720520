export const getPerformers = (data) => {
	if (
		data.performers &&
		Array.isArray(data.performers) &&
		data.performers.length
	) {
		return (
			<span title={data.performers.join(', ')}>
				{data.performers.join(', ')}
			</span>
		);
	}

	if (
		data.performers &&
		!Array.isArray(data.performers) &&
		typeof data.performers === 'object'
	) {
		return (
			<span title={Object.values(data.performers).join(', ')}>
				{Object.values(data.performers).join(', ')}
			</span>
		);
	}

	return <span>-</span>;
};

export const getDraftPerformers = (data) => {
	if (
		data.recording_performers &&
		Array.isArray(data.recording_performers) &&
		data.recording_performers.length
	) {
		const performersTitles = data.recording_performers.map(
			(item) => item.title
		);
		return performersTitles.join(', ');
	}

	if (data.performers && typeof data.performers === 'string') {
		return data.performers;
	}

	return '-';
};
