export const formatPerformers = (performers) => {
	if (performers && (typeof performers === 'string' || typeof performers === 'number')) {
		return [{ name: performers.toString() }, ''];
	} else {
		const resultArr = performers.map((item) => {
			if (typeof item === 'string' || typeof item === 'number') {
				return { name: item.toString() };
			}
			return item;
		});

		return [...resultArr, ''];
	}
};
