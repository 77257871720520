const BROMA_COM_URLS = {
	BROMA_COM_PROD: '/rod.broma16.com',
	BROMA_COM_STAGE_B: '/b.techrod.pro',
	BROMA_COM_STAGE_C: '/c.techrod.pro',
	DGTAL_PROD: '/dgtal.techrod.pro',
	DGTAL_STAGE: '/dgtal-stage.techrod.pro',
};

export const isForCom =
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_PROD) ||
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_STAGE_C) ||
	window.location.href.includes(BROMA_COM_URLS.BROMA_COM_STAGE_B) ||
	window.location.href.includes(BROMA_COM_URLS.DGTAL_PROD) ||
	window.location.href.includes(BROMA_COM_URLS.DGTAL_STAGE);

export default BROMA_COM_URLS;
