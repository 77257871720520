// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';

// Context
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

// UI
import { Button } from 'components/Buttons/index';
import { Col, Container, Row } from 'components/Grid';
import { Label } from 'components/Labels/index';
import { Register as RegisterForm } from 'forms/index';

// Services
import { withAuth, withRoot, withUI } from 'hocs';
import { Hidden } from 'react-grid-system';
import { compose } from 'recompose';
import { auth } from 'services';

// Styles
import styles from './Registration.module.css';

const Registration = () => {
	const {
		isAllowLanguageDetection,
		isAuth,
		errors,
		isRedirect,
		isRegisterInProcess,
		register,
		isEmailValid,
	} = useContext(AuthContext);
	const { putResendSMSTime, isAllowSignup } = useContext(UIContext);
	const history = useHistory();

	const [isReady, setIsReady] = useState(false);
	const [code, setCode] = useState(null);
	const [url, setUrl] = useState('');

	const handleRedirectLogic = (countryCode) => {
		switch (countryCode) {
			case 'RU':
				if (
					document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
				) {
					setIsReady(true);
				} else {
					window.location.assign(
						`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
					);
				}
				break;
			case 'BY':
				if (
					document.location.origin === process.env.REACT_APP_REDIRECT_URL_RU
				) {
					setIsReady(true);
				} else {
					window.location.assign(
						`${process.env.REACT_APP_REDIRECT_URL_RU}/registration`
					);
				}
				break;
			default:
				if (
					document.location.origin === process.env.REACT_APP_REDIRECT_URL_COM
				) {
					setIsReady(true);
				} else {
					window.location.assign(
						`${process.env.REACT_APP_REDIRECT_URL_COM}/registration`
					);
				}
				break;
		}
	};

	const checkInstanceAndRedirect = () => {
		if (!isRedirect) {
			setIsReady(true);
			return;
		}

		if (process.env.REACT_APP_CLIENT === 'hitmaker') {
			setIsReady(true);
		} else if (!isAllowLanguageDetection) {
			setIsReady(true);
		} else if (isAllowLanguageDetection) {
			auth.getCountryCode().then((res) => {
				const countryCode = res.data.data;
				setCode(countryCode);

				const url =
					countryCode === 'RU'
						? process.env.REACT_APP_API_COM
						: process.env.REACT_APP_API_RU;
				setUrl(url);

				const client = axios.create({
					withCredentials: true,
					baseURL: `${url}`,
				});

				client
					.post(`/auth/ini`)
					.then(() => {
						handleRedirectLogic(countryCode);
					})
					.catch((error) => {
						if (error.response && error.response.status === 423) {
							handleRedirectLogic(countryCode);
						} else {
							throw error;
						}
					});
			});
		}
	};

	// Защита от ddos атак при регистрации
	useEffect(() => {
		auth
			.ini()
			.then()
			.catch((error) => console.error(error));
	}, []);

	useEffect(() => {
		checkInstanceAndRedirect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAllowLanguageDetection]);

	const registerAccount = (registerData) => {
		putResendSMSTime();
		register(registerData);
	};

	if (isAuth) {
		return <Redirect to={'/'} />;
	}

	return (
		isReady && (
			<Container fluid className={styles.page}>
				<Row className={styles.page}>
					<Col xs={12} sm={6} md={5} className={styles.login}>
						<Hidden xs>
							<div>
								<Label black className={'title'}>
									<FormattedHTMLMessage id={'rod.login.header'} />
								</Label>
								<Label black font="--gilroy-Medium-24">
									<FormattedHTMLMessage
										id={'rod.register.text.already_have_account'}
									/>
								</Label>
								<div className={'controls'}>
									<Button
										className={styles.loginBtn}
										onClick={() => {
											history.push('/login');
										}}
										text={<FormattedHTMLMessage id={'rod.action.login'} />}
									/>
								</div>
							</div>
						</Hidden>
					</Col>

					<Col xs={12} sm={6} md={7} className={styles.register}>
						<RegisterForm
							onSubmit={registerAccount}
							isEmailValid={isEmailValid}
							errors={errors}
							isRegisterInProcess={isRegisterInProcess}
							isAllowSignup={isAllowSignup}
							isAllowLanguageDetection={isAllowLanguageDetection}
							countryCode={code}
							url={url}
						/>
					</Col>
				</Row>
			</Container>
		)
	);
};

export default compose(withRoot, withAuth, withRouter, withUI)(Registration);
