export default function isItemSelected(selectedItem, value) {
	if (
		!value ||
		(Array.isArray(value) && !value.length) ||
		(typeof value === 'object' && !Object.keys(value).length)
	)
		return false;
	if (
		!Array.isArray(value) &&
		(!selectedItem.id || !value.id) &&
		(selectedItem.title_ru === value.title_ru ||
			selectedItem.title_en === value.title_en)
	) {
		return true;
	}
	if (
		Array.isArray(value) &&
		value.some((element) => element.id && element.id === selectedItem.id)
	) {
		return true;
	} else if (selectedItem.id && value.id && selectedItem.id === value.id) {
		return true;
	}
	return false;
}
