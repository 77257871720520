export const handleErrors = (error, setErrors) => {
	if (error.response) {
		const { status, data } = error.response;
		switch (status) {
			case 422:
				if (data.errors) {
					const errorKeys = Object.keys(data.errors);
					if (
						data.errors.title &&
						data.errors.title[0] &&
						data.errors.title[0]?.value === '255'
					) {
						setErrors((prev) => ({
							...prev,
							title: [{ rule: 'max_length' }],
						}));
					}
					if (errorKeys.includes('ipi_name_number')) {
						setErrors((prev) => ({
							...prev,
							ipi_name_number: [{ rule: 'wrong_format' }],
						}));
					}
					if (errorKeys.includes('ipn')) {
						setErrors((prev) => ({
							...prev,
							ipn: [{ rule: 'wrong_format' }],
						}));
					}
					if (errorKeys.includes('isni')) {
						setErrors((prev) => ({
							...prev,
							isni: [{ rule: 'wrong_format' }],
						}));
					}
					setErrors((prev) => ({
						...prev,
						422: [{ rule: 'an error occurred' }],
					}));
				}
				break;
			case 409:
				console.error(data);
				setErrors((prev) => ({
					...prev,
					409: [{ rule: 'an error occurred' }],
				}));
				break;
			default:
				console.error('An error occurred:', data);
				setErrors((prev) => ({
					...prev,
					error: [{ rule: 'an error occurred' }],
				}));
		}
	} else {
		console.error('An unexpected error occurred:', error.message);
	}
};
