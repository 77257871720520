// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import emptyIcon from 'images/playlist/emptyPlaylistIcon.png';

// Styles
import styles from './PlaylistEmpty.module.css';

function PlaylistEmpty() {
	return (
		<div className={styles.emptyPlaylistWrapper}>
			<img
				className={styles.emptyPlaylistIcon}
				src={emptyIcon}
				alt="empty playlist image"
			/>
			<p className={styles.emptyPlaylistTitle}>
				<FormattedMessage id={'rod.statistic.playlists.no-playlists.title'} />
			</p>
			<p className={styles.emptyPlaylistDescription}>
				<FormattedMessage
					id={'rod.statistic.playlists.no-playlists.description'}
				/>
			</p>
		</div>
	);
}

export default PlaylistEmpty;
