// Core
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

// Constants
import ModalTypes from 'constants/modalTypes';

// Context
import { UIContext } from 'contexts/UIContext';

// UI
import NewRecordingForm from '../NewRecordingForm/NewRecordingForm';

// Styles
import s from './RecordingsList.module.css';

const RecordingsList = ({
	recordings,
	onRemoveClick,
	newRecordings,
	recordingSave,
	data,
	errors,
	setErrors,
	isEdit,
	disabledSaveBtn,
	handleChangeArtist,
	handleOpenModal,
	handleAddArtist,
	handleDeleteArtist,
}) => {
	const { showModal } = useContext(UIContext);

	return (
		<div>
			<ul className={s.list_items}>
				{recordings &&
					recordings
						.sort((a, b) => a.id - b.id)
						.map((recording, index) => (
							<li key={recording.id} className={s.item}>
								<NewRecordingForm
									data={data}
									number={index + 1}
									recording={recording}
									onClickDeleteItem={showModal(
										{
											title: (
												<FormattedMessage id="rod.compositions.delete_confirm" />
											),
											text: (
												<FormattedMessage id="rod.compositions.delete_text" />
											),
											onAction: () => {
												onRemoveClick(recording.id);
											},
											confirmBtnTxt: (
												<FormattedMessage id={'rod.modal.yes_delete'} />
											),
											declineBtnTxt: (
												<FormattedMessage id={'rod.modal.cancel'} />
											),
										},
										ModalTypes.FULL_MODAL
									)}
									recordingSave={recordingSave}
									isEdit={isEdit}
									addNewRecordings={newRecordings}
									errors={errors}
									setErrors={setErrors}
									disabledSaveBtn={disabledSaveBtn}
									handleChangeArtist={handleChangeArtist}
									handleOpenModal={handleOpenModal}
									handleAddArtist={handleAddArtist}
									handleDeleteArtist={handleDeleteArtist}
								/>
							</li>
						))}
			</ul>
		</div>
	);
};

export default RecordingsList;
