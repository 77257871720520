// Core
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './InfoHelp.module.css';

const InfoHelp = ({ artist }) => {
	return (
		<div
			className={styles.container}
			style={
				artist.outlets && !artist.outlets.length ? { marginTop: '32px' } : {}
			}
		>
			<FormattedMessage id={'rod.artist.information.helper'} />
		</div>
	);
};

export default InfoHelp;
