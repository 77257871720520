// Core
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { accounts } from 'services';
import { AuthContext } from 'contexts/AuthContext';

// Context
import { LangContext } from 'contexts/LangContext';

// Conponents
import PlaylistItem from './PlaylistItem/PlaylistItem';
import PlaylistEmpty from './PlaylistEmpty/PlaylistEmpty';

// UI
import { Pagination } from 'material-design/components';
import PlaylistSkeleton from './PlayListSkeleton/PlaylistSkeleton';

// Styles
import styles from './Playlist.module.css';

function Playlist() {
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [playlistData, setPlaylistData] = useState([]);
	const [loading, setLoading] = useState(true);
	const { accountId } = useContext(AuthContext);
	const { lang } = useContext(LangContext);

	useEffect(() => {
		const fetchPlaylistData = async () => {
			try {
				const res = await accounts.getStatisticPlaylist(accountId, page);
				const formattedPlaylists = res.data.playlists?.map((playlist) => {
					return {
						...playlist,
						outlet: capitalizeFirstLetter(playlist.outlet),
						followers: formatNumber(playlist.followers),
						streams: formatNumber(playlist.streams),
					};
				});
				setPlaylistData(formattedPlaylists);
				setTotal(res.data.total);
				setCurrentPage(res.data.current_page);
			} catch (err) {
				console.error(err);
			} finally {
				setLoading(false);
			}
		};

		const capitalizeFirstLetter = (string) => {
			if (!string) return string;
			return string.charAt(0).toUpperCase() + string.slice(1);
		};

		const formatNumber = (num) => {
			return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
		};

		fetchPlaylistData();
	}, [accountId, page]);

	return (
		<>
			<div className={styles.wrapper}>
				<div className={styles.header}>
					<p className={styles.title}>
						<FormattedHTMLMessage id={'rod.navbar.statistic.playlists'} />
					</p>
					<p className={styles.subtitle}>
						<FormattedHTMLMessage id={'rod.statistic.playlists.subtitle'} />
					</p>
				</div>
				{loading ? (
					<div className={styles.skeletonWrapper}>
						<PlaylistSkeleton />
					</div>
				) : playlistData && playlistData.length > 0 ? (
					<div>
						<div className={styles.headerInfo}>
							<p className={styles.headerInfoName}>
								<FormattedMessage
									id={'rod.statistic.playlists.playlist_name'}
								/>
							</p>
							<p className={styles.headerInfoStreams}>
								<FormattedMessage
									id={'rod.statistic.playlists.playlist_streams'}
								/>
							</p>
							<p className={styles.headerInfoFollowers}>
								<FormattedMessage
									id={'rod.statistic.playlists.playlist_followers'}
								/>
							</p>
							<p className={styles.headerInfoPlatform}>
								<FormattedMessage
									id={'rod.statistic.playlists.playlist_platform'}
								/>
							</p>
							<p
								className={
									lang === 'en'
										? styles.headerInfoLinkEn
										: styles.headerInfoLink
								}
							>
								<FormattedMessage
									id={'rod.statistic.playlists.playlist_link'}
								/>
							</p>
						</div>

						<ul className={styles.playlistList}>
							{playlistData.map((item, idx) => (
								<PlaylistItem
									key={idx}
									item={item}
									idx={idx}
									currentPage={currentPage}
								/>
							))}
						</ul>
					</div>
				) : (
					<PlaylistEmpty />
				)}
			</div>
			{total > 10 && playlistData.length > 0 && (
				<div className={styles.paginationWrapper}>
					<Pagination
						page={page}
						total={total}
						paginate={(pageNumber) => {
							if (pageNumber !== page) {
								setLoading(true);
								setPage(pageNumber);
							}
						}}
						items_on_page={10}
					/>
				</div>
			)}
		</>
	);
}

export default Playlist;
