// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Icons
import add_box_line from 'images/add_box_line.svg';

// Styles
import styles from './AddButton.module.css';

const AddButton = ({ disabled, onClick, top }) => {
	return (
		<button
			className={styles.addButton}
			style={{ top: top }}
			disabled={disabled}
			onClick={onClick}
		>
			<img src={add_box_line} alt="plus" />
			<span>
				<FormattedMessage
					id={'rod.release.create.step.release.label.artists.add_performer'}
				/>
			</span>
		</button>
	);
};

export default AddButton;
