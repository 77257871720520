import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const MuiMultipleCheckedSkeleton = () => {
	return (
		<Box
			style={{
				padding: '0 25px 16px 25px',
			}}
		>
			<Skeleton height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation="wave" height={36} />
			<Skeleton animation={false} height={36} />
		</Box>
	);
};

export default MuiMultipleCheckedSkeleton;
