// Core
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './SidepageModal.module.css';

const SidepageModal = ({
	active,
	setActive,
	customWidth,
	modalTitle,
	headerBottom,
	children,
}) => {
	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);

	return (
		<>
			<div
				className={
					active ? `${styles.Overlay} ${styles.OverlayActive}` : styles.Overlay
				}
			/>
			<div
				className={active ? `${styles.modal} ${styles.active}` : styles.modal}
				onClick={() => setActive(false)}
			>
				<div
					key={Math.random() * 100}
					className={styles.container}
					style={customWidth ? { width: customWidth } : { width: '416px' }}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={styles.modalHeader} style={headerBottom ? {paddingBottom: headerBottom} : {}}>
						<div className={styles.modalHeader__titleWrapper}>
							{modalTitle && (
								<span className={styles.modalHeader__title}>
									<FormattedMessage id={'rod.release.info.filter'} />
								</span>
							)}
							<div className={styles.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.modalBody}>
						{children}
					</div>
				</div>
			</div>
		</>
	);
};

export default SidepageModal;
