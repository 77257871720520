// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// Utils
import { formatDate, getDraftPerformers } from 'utils';
import { withUI, withAuth, withRoot } from 'hocs';
import { compose } from 'recompose';
import { accounts, dictionaries, admin } from 'services';
import lscache from 'lscache';
import { setCache } from 'services/cacheHelper/setCache';
import { getDraftStatus } from './utils/getDraftStatus';

// UI
import { Pagination, Input } from 'material-design/components';
import { Loading } from 'components';
import TableHeader from './TableHeader/TableHeader';

// Icons
import { Cd } from 'material-design/svg';
import { CdWhite } from 'material-design/svg';
import { Nota } from 'material-design/svg';
import { NotaWhite } from 'material-design/svg';

// Styles
import styles from './AdminAccountDrafts.module.css';

const AdminAccountDrafts = (props) => {
	const lang = localStorage.getItem('lang');
	const { accountId } = props;

	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);
	const [releases, setReleases] = useState([]);
	const [compositionTypes, setCompositionTypes] = useState([]);
	const [drafts, setDrafts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [value, setValue] = useState('');
	const [isReqLive] = useState(false);

	useEffect(() => {
		if (releases.length === 0) {
			new Promise((resolve, reject) => {
				const releaseTypesCache = lscache.get('releaseTypesCache');
				if (
					releaseTypesCache &&
					!isReqLive &&
					releaseTypesCache.lang === lang
				) {
					setReleases(releaseTypesCache.data);
					resolve(releaseTypesCache.data);
				} else {
					accounts
						.getReleaseTypes(lang)
						.then((releases) => {
							setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
							setReleases(releases.data.data);
							resolve(releases.data.data);
						})
						.catch((error) => {
							console.error('Error', error);
						});
				}
			});
		} else {
			new Promise((resovle, reject) => {
				resovle(releases);
			});
		}
		if (compositionTypes.length === 0) {
			new Promise((resolve, reject) => {
				dictionaries
					.getCompositionTypes()
					.then((res) => {
						setCompositionTypes(res.data.data);
						resolve(res.data.data);
					})
					.catch((error) => {
						console.error('Error', error);
					});
			});
		} else {
			new Promise((resolve, reject) => {
				resolve(compositionTypes);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const releaseTypesCache = lscache.get('releaseTypesCache');
		if (releaseTypesCache && !isReqLive && releaseTypesCache.lang === lang) {
			setReleases(releaseTypesCache.data);
		} else {
			accounts
				.getReleaseTypes(lang)
				.then((releases) => {
					setCache('releaseTypes', 'releaseTypesCache', releases.data.data);
					setReleases(releases.data.data);
				})
				.catch((error) => {
					console.error('Error', error);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	const onChange = (filter) => {
		setValue(filter);
		if (releases && compositionTypes)
			if (filter.length >= 3) {
				setLoading(true);
				admin
					.getAdminAccountDrafts(accountId, filter, '', page)
					.then((res) => {
						res = res.data;
						res.data.forEach((item) => {
							// item.status_item = getItemStatus(item.statuses);
							// item.status_title = checkStatus(item.statuses);
						});
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(res.data);
						setDrafts(draftsWithType);
						setPage(1);
						setLoading(false);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			} else if (!filter) {
				setLoading(true);
				admin
					.getAdminAccountDrafts(accountId, '', '', page)
					.then((res) => {
						res = res.data;
						res.data.forEach((item) => {
							// item.status_item = getItemStatus(item.statuses);
							// item.status_title = checkStatus(item.statuses);
						});
						setTotal(res.total);
						const draftsWithType = addTypesToDrafts(res.data);
						setDrafts(draftsWithType);
						setPage(1);
						setLoading(false);
					})
					.catch((error) => {
						console.info('Error', error);
					});
			}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// const optimizedV = useCallback(debounce(onChange, 250), []);

	useEffect(() => {
		if (releases?.length !== 0 && compositionTypes !== 0) {
			admin
				.getAdminAccountDrafts(accountId, value, '', page)
				.then((res) => {
					const draftsWithType = addTypesToDrafts(res.data.data);
					setDrafts(draftsWithType);
					setTotal(res.data.total);
					loading && setLoading(false);
				})
				.catch((err) => {
					console.error('Drafts -changePage - error: ', err);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releases, compositionTypes, page]);

	const addTypesToDrafts = (initialDrafts) => {
		if (initialDrafts.length !== 0) {
			initialDrafts.forEach((item) => {
				switch (item.draft_type) {
					case 'release':
						releases.forEach((releaseItem) => {
							if (item.type_id.toString() === releaseItem.id.toString())
								item.type = releaseItem.title;
						});
						break;
					case 'composition':
						compositionTypes.forEach((compositionItem) => {
							if (item.type_id.toString() === compositionItem.id.toString())
								item.type =
									lang === 'en'
										? compositionItem.title_en
										: compositionItem.title_ru;
						});
						break;

					default:
						break;
				}

				if (item.created_date) {
					item.created_date = item.created_date.replace(
						/(\d{4})-(\d{2})-(\d{2}).+/gm,
						'$3.$2.$1'
					);
				}
				return item;
			});
			setLoading(false);
		}
		return initialDrafts;
	};

	const {
		UIContext: { upload },
	} = props;

	return (
		<>
			<Input handleOnChange={onChange} />
			{loading ? (
				<Loading className={styles.loader} />
			) : drafts?.length ? (
				<div className={styles.repertoire__mainContent}>
					<div>
						<TableHeader />

						<ul className={styles.repertoire__songItemsWrapper}>
							{drafts &&
								drafts.map((item, index) => {
									return item.step === 'moderation' ||
										item.step === 'completed' ||
										item.step === 'parsed' ? (
										<li className={styles.itemWrapper}>
											<div
												className={`${styles.draft_moderation} ${styles.song__table}`}
											>
												<div>
													<span>{item.title}</span>
													{item.title && <span>{item.title}</span>}
												</div>
												<div>
													<span title={getDraftPerformers(item)}>{getDraftPerformers(item)}</span>
												</div>
												<div>
													<span>{item.type}</span>
													{item.type && <span>{item.type}</span>}
												</div>
												<div>
													<span>
														<FormattedMessage
															id={`rod.draft.release.${item.step}`}
														/>
													</span>
													{item.step && (
														<span>
															<FormattedMessage
																id={`rod.draft.release.${item.step}`}
															/>
														</span>
													)}
												</div>
												<div>
													{item.updated_at && (
														<span>{formatDate(item.updated_at)}</span>
													)}
												</div>

												{item.draft_type === 'release' ? (
													<CdWhite
														className={styles.repertoire__songItemLogo}
													/>
												) : (
													<NotaWhite
														className={styles.repertoire__songItemLogo}
													/>
												)}
											</div>
										</li>
									) : (
										<li className={styles.itemWrapper}>
											<div className={styles.itemLink}>
												<div
													className={
														item.status_title === 'disputed' ||
														item.status_title === 'not_ready'
															? `${styles.repertoire__songItemError} ${styles.song__table}`
															: styles.song__table
													}
												>
													<div>
														<span>{item.title}</span>
														{item.title && <span>{item.title}</span>}
													</div>
													<div>
														<span title={getDraftPerformers(item)}>{getDraftPerformers(item)}</span>
													</div>
													<div>
														<span>{item.type}</span>
														{item.type && <span>{item.type}</span>}
													</div>
													<div>
														<span>{getDraftStatus(item)}</span>
														{getDraftStatus(item) && (
															<span>{getDraftStatus(item)}</span>
														)}
													</div>
													<div>
														{item.updated_at && (
															<span>{formatDate(item.updated_at)}</span>
														)}
													</div>
													{item.draft_type === 'release' ? (
														<Cd className={styles.repertoire__songItemLogo} />
													) : (
														<Nota className={styles.repertoire__songItemLogo} />
													)}
												</div>
											</div>
										</li>
									);
								})}
						</ul>
					</div>
					{total > 10 && (
						<Pagination
							page={page}
							total={total}
							paginate={(pageNumber) => setPage(pageNumber)}
							items_on_page={10}
						/>
					)}
				</div>
			) : (
				<div className={styles.repertoire__mainContentEmpty}>
					<Cd width={64} />
					{upload.drafts && (
						<span>
							<FormattedMessage id={'rod.admin.no-data'} />
						</span>
					)}
				</div>
			)}
		</>
	);
};

export default compose(withUI, withAuth, withRoot)(AdminAccountDrafts);
